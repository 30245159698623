import {
  createUserWithEmailAndPassword,
  signOut as fbSignOut,
  onAuthStateChanged as firebaseOnAuthStateChanged,
  getAuth,
  GoogleAuthProvider,
  IdTokenResult,
  isSignInWithEmailLink,
  sendEmailVerification,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  signInWithPopup,
  User,
} from "firebase/auth";

// Types
interface CallbackProps {
  user: User | null;
}

type CallbackFunction = (context: CallbackProps) => void;

const googleAuthProvider = new GoogleAuthProvider();

export const GoogleAuthSignIn = () =>
  signInWithPopup(getAuth(), googleAuthProvider);
export const EmailAuthSignIn = (email: string, password: string) =>
  signInWithEmailAndPassword(getAuth(), email, password);
export const SendPasswordlessEmailLink = async (email: string) =>
  await sendSignInLinkToEmail(getAuth(), email, {
    url: `https://${window.location.hostname}/magic-link`,
    // TODO: This must be true or else the link will not work in the emulator - but it should be false as no apps exist
    handleCodeInApp: true,
  });
export const PasswordlessEmailLinkSignIn = async (email: string) =>
  signInWithEmailLink(getAuth(), email, window.location.href);
export const IsMagicEmailLink = () =>
  isSignInWithEmailLink(getAuth(), window.location.href);
export const refreshToken = (forceRefresh: boolean) => {
  const user = getAuth().currentUser;

  if (!user) {
    throw new Error(
      "Cannot refresh the token if the user has never authenticated",
    );
  }

  return user.getIdToken(forceRefresh);
};
export const refreshUser = async () => {
  const auth = getAuth();
  if (auth.currentUser) {
    await auth.currentUser.reload();
    return auth.currentUser;
  } else {
    return null;
  }
};
export const EmailAuthCreateUser = (email: string, password: string) =>
  createUserWithEmailAndPassword(getAuth(), email, password);
export const signOut = () => fbSignOut(getAuth());

/**
 * @throws {Error} Throws if the user isn't signed in
 */
export const verifyEmail = () => {
  const user = getAuth().currentUser;

  if (!user) {
    throw new Error("Cannot verify the email of someone who isn't logged in");
  }

  return sendEmailVerification(user, {
    url: `https://${window.location.hostname}`,
  });
};
export const sendPasswordReset = (email: string) =>
  sendPasswordResetEmail(getAuth(), email, {
    url: `https://${window.location.hostname}`,
  });
export const getUserToken = async (): Promise<{
  token?: IdTokenResult["token"];
  claims: { admin?: boolean };
}> => {
  const result = await getAuth().currentUser?.getIdTokenResult();
  return {
    token: result?.token,
    claims: result?.claims.admin
      ? { admin: Boolean(result?.claims.admin) }
      : ({} as { admin?: boolean }),
  };
};

export const onAuthStateChanged = (callback: CallbackFunction) => {
  firebaseOnAuthStateChanged(getAuth(), (user) => {
    callback({ user });
  });
};
