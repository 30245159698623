import { z } from "zod";

export const DiscoveryBookingLinkSchema = z.coerce
  .string()
  .url()
  .or(z.literal(""))
  .optional();

export const OrganisationSchema = z.object({
  id: z.string(),
  name: z.string(),
  internalNote: z.string(),
  trelloCardId: z.string(),
  createdDate: z.coerce.date(),
  resultsAvailable: z.coerce.boolean().optional().default(false),
  discoveryBookingLink: DiscoveryBookingLinkSchema,
});
export type Organisation = z.infer<typeof OrganisationSchema>;

export const NewOrganisationSchema = OrganisationSchema.omit({ id: true });
export type NewOrganisation = z.infer<typeof NewOrganisationSchema>;
