import { QueryDocumentSnapshot, SnapshotOptions } from "@firebase/firestore";
import {
  collection,
  doc,
  DocumentData,
  DocumentReference,
  Firestore,
  FirestoreDataConverter,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  Timestamp,
  where,
} from "firebase/firestore";
import { Md5 } from "ts-md5";
import { z } from "zod";
import { Organisation } from "../organisation/model/Organisation.ts";
import { TeamDoesNotExistError, TeamRepository } from "./interface";
import { NewTeam, Team, TeamSchema } from "./model/Team.ts";

const firebaseTeamSchema = TeamSchema.omit({
  id: true,
  organisationId: true,
  surveyId: true,
  createdDate: true,
}).extend({
  createdDate: z.instanceof(Timestamp),
  organisation: z
    .custom<DocumentReference>((data) => data instanceof DocumentReference)
    .nullish(),
  survey: z.custom<DocumentReference>(
    (data) => data instanceof DocumentReference,
  ),
});
interface FirebaseTeam
  extends DocumentData,
    z.infer<typeof firebaseTeamSchema> {}

type NewTeamWithOrgId = NewTeam & { organisationId: string };

class TeamConverter
  implements FirestoreDataConverter<Team | NewTeamWithOrgId, FirebaseTeam>
{
  private readonly firestore: Firestore;

  constructor(firestore: Firestore) {
    this.firestore = firestore;
  }

  toFirestore(team: Team | NewTeamWithOrgId): FirebaseTeam {
    const { surveyId, createdDate, organisationId, ...fbt } =
      Object.fromEntries(
        Object.entries(team).filter(([key]) => key !== "id"),
      ) as Omit<Team, "id">;

    return {
      ...fbt,
      survey: doc(this.firestore, "surveys", surveyId),
      organisation: organisationId
        ? doc(this.firestore, "organisations", organisationId)
        : null,
      createdDate: Timestamp.fromDate(createdDate),
    } as FirebaseTeam;
  }

  fromFirestore(
    snapshot: QueryDocumentSnapshot<FirebaseTeam, FirebaseTeam>,
    options: SnapshotOptions,
  ): Team {
    const { organisation, survey, createdDate, ...snapshotData } =
      snapshot.data(options);

    return TeamSchema.parse({
      ...snapshotData,
      id: snapshot.id,
      organisationId: organisation ? organisation.id : null,
      surveyId: survey.id,
      createdDate: createdDate.toDate(),
    });
  }
}

export class FirebaseTeamRepository implements TeamRepository {
  private readonly firestore: Firestore;

  // Define Firestore converter
  private readonly converter;

  constructor(firestore: Firestore) {
    this.firestore = firestore;
    this.converter = new TeamConverter(firestore);
  }

  async fetch(): Promise<Team[]> {
    // Use withConverter when getting a document or collection
    const teamsSnapshot = await getDocs(
      collection(this.firestore, "teams").withConverter(this.converter),
    );

    return teamsSnapshot.docs.map((doc) => doc.data()) as Team[];
  }

  async fetchByOrganisationId(orgId: string | null): Promise<Team[]> {
    const teamsCol = collection(this.firestore, "teams");

    // This is here to allow non-admin users to query the team list for their
    // organisation, and the alternative is for admin users.
    // This would be broken if we wanted a non-admin user to list all the teams without an organisation
    const orgQuery = orgId
      ? query(
          teamsCol,
          where(
            "organisation",
            "==",
            doc(this.firestore, "organisations", orgId),
          ),
          orderBy("name"),
        )
      : query(teamsCol, orderBy("name"));
    const teamsSnapshot = await getDocs(orgQuery.withConverter(this.converter));

    const teams = teamsSnapshot.docs.map((doc) => doc.data()) as Team[];
    return teams.filter((team) =>
      (orgId ?? null) === null
        ? team.organisationId === null
        : team.organisationId === orgId,
    );
  }

  async fetchById(teamId: string): Promise<Team> {
    const teamDoc = await getDoc(
      doc(this.firestore, "teams", teamId).withConverter(this.converter),
    );
    const data = teamDoc.data() as Team | undefined;
    if (!teamDoc.exists() || !data) {
      throw new TeamDoesNotExistError("No team with ID: " + teamId);
    }
    return data;
  }

  async save(team: NewTeam, organisation?: Organisation): Promise<Team> {
    const id = organisation
      ? Md5.hashStr([organisation.name, team.name].join("-"))
      : null;

    const reference = id
      ? doc(this.firestore, "teams", id).withConverter(this.converter)
      : doc(collection(this.firestore, "teams")).withConverter(this.converter);
    await setDoc(reference, {
      ...team,
      organisationId: organisation?.id,
    } as NewTeamWithOrgId);

    const data: Team | undefined = (await getDoc(reference)).data() as
      | Team
      | undefined;
    if (!data) {
      throw new Error("Failed to retrieve saved team");
    }

    return data;
  }
}
