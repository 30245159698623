import { SessionRepository } from "./interface";
import { Session } from "./model/Session.ts";

export class InMemorySessionsRepository implements SessionRepository {
  private readonly datastore: Record<string, Session>;

  constructor() {
    this.datastore = {};
  }

  save(session: Session): Promise<void> {
    this.datastore[session.event.uuid] = session;

    return Promise.resolve();
  }

  fetchByUUID(uuid: string): Promise<Session | null> {
    return Promise.resolve(this.datastore[uuid] ?? null);
  }

  fetchByTeamIDAndType(teamId: string, name: string): Promise<Session[]> {
    return Promise.resolve(
      Object.values(this.datastore).filter((s) => {
        return s.teamId === teamId && s.event.name === name;
      }),
    );
  }
}
