import { QueryDocumentSnapshot } from "@firebase/firestore";
import {
  addDoc,
  collection,
  doc,
  DocumentData,
  Firestore,
  getDoc,
} from "firebase/firestore";
import { QuestionRepository } from "./interface";
import { NewQuestion, Question, QuestionSchema } from "./model/question";

interface FirebaseQuestion extends DocumentData, Omit<Question, "id"> {}

class QuestionConverter {
  toFirestore(session: Question | NewQuestion): FirebaseQuestion {
    return Object.fromEntries(
      Object.entries(session).filter(([key]) => key !== "id"),
    ) as FirebaseQuestion;
  }

  fromFirestore(snapshot: QueryDocumentSnapshot<FirebaseQuestion>): Question {
    const snapshotData = snapshot.data();

    return QuestionSchema.parse({ ...snapshotData, id: snapshot.id });
  }
}

export class FirebaseQuestionRepository implements QuestionRepository {
  private readonly firestore: Firestore;
  private readonly converter: QuestionConverter;

  constructor(firestore: Firestore) {
    this.firestore = firestore;
    this.converter = new QuestionConverter();
  }

  async save(question: NewQuestion): Promise<Question> {
    const reference = (
      await addDoc(collection(this.firestore, "questions"), question)
    ).withConverter(this.converter);

    return { ...question, id: reference.id };
  }

  async fetchById(id: string): Promise<null | Question> {
    const documentSnapshot = await getDoc(
      doc(this.firestore, "questions", id).withConverter(this.converter),
    );

    if (!documentSnapshot.exists()) {
      return null;
    }

    const documentData = documentSnapshot.data();

    return { ...documentData, id };
  }
}
