import { FC, PropsWithChildren, Suspense, useCallback } from "react";
import { Outlet } from "react-router-dom";
import { NavBar } from "../../navigation/bar/NavBar.tsx";
import { CookieBanner } from "../../navigation/cookie-banner/CookieBanner.tsx";
import Footer from "../landmarks/Footer.tsx";
import LoadingSpinner from "../landmarks/LoadingSpinner.tsx";

const Layout: FC<
  PropsWithChildren<{
    readonly navigation?: boolean;
    readonly center?: boolean;
    readonly uncontained?: boolean;
  }>
> = ({ children, navigation = false, center = false, uncontained = false }) => {
  const containerClasses = useCallback(
    (center?: boolean, uncontained?: boolean) =>
      [
        uncontained ? null : "container",
        uncontained ? null : "mx-auto",
        uncontained ? "w-full" : null,
        "flex-grow",
        "flex",
        center ? "justify-center" : null,
        center ? "items-center" : null,
        center ? "text-center" : null,
      ]
        .filter(Boolean)
        .join(" "),
    [],
  );

  const outerDivClasses = [
    "flex",
    "flex-col",
    "min-h-screen",
    uncontained ? null : "gap-6",
    "min-w-full",
  ]
    .filter(Boolean)
    .join(" ");

  const mainClasses = containerClasses(center, uncontained);
  const fallbackClasses = containerClasses(true);

  return (
    <>
      <div className={outerDivClasses}>
        {navigation ? <NavBar /> : null}
        <Suspense
          fallback={
            <main className={fallbackClasses}>
              <LoadingSpinner />
            </main>
          }
        >
          <main className={mainClasses}>
            {children ? children : <Outlet />}
          </main>
        </Suspense>
        <Footer />
      </div>
      <CookieBanner />
    </>
  );
};
export default Layout;
