import { z } from "zod";

export const ModuleQuestionSchema = z.object({
  id: z.string(),
  version: z.string(),
  title: z.string(),
  summary: z.string(),
});
export type ModuleQuestion = z.infer<typeof ModuleQuestionSchema>;
export const ModuleVersionSchema = z.object({
  title: z.string(),
  description: z.string(),
  questions: z.array(ModuleQuestionSchema),
});

export type ModuleVersion = z.infer<typeof ModuleVersionSchema>;
export const ModuleSchema = z.object({
  id: z.string(),
  versions: z.record(ModuleVersionSchema),
});
export type Module = z.infer<typeof ModuleSchema>;
