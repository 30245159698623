import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  useContext,
  useReducer,
} from "react";
import { Module } from "../../../repositories/module/model/module.ts";

export interface DataCacheState {
  modules: undefined | Record<string, Module>;
}

type DataCacheStateKeys = keyof DataCacheState;

interface SetAction<T extends DataCacheStateKeys> {
  type: "set";
  key: T;
  state: DataCacheState[T];
}

export type DataCacheAction = SetAction<keyof DataCacheState>;

function reducer(state: DataCacheState, action: DataCacheAction) {
  switch (action.type) {
    case "set":
      return { ...state, [action.key]: action.state };
  }
}

export type DataCacheContextContents = {
  state: DataCacheState;
  dispatch: Dispatch<DataCacheAction>;
} | null;
export const DataCacheContext = createContext<DataCacheContextContents>(null);

export const initializerArg = {
  modules: undefined,
};

export const DataCacheProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initializerArg);
  return (
    <DataCacheContext.Provider value={{ state, dispatch }}>
      {children}
    </DataCacheContext.Provider>
  );
};

/**
 * Retrieves the current data-cache context from the React context API.
 * Throws an error if the context is not available.
 *
 * @throws {Error} If the data-cache context is not available.
 */
export function useDataCacheContext(): NonNullable<DataCacheContextContents> {
  const context = useContext(DataCacheContext);

  if (!context) {
    throw new Error("No data-cache context available, initialise the provider");
  }

  return context;
}
