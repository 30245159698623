import semver from "semver";

/**
 *
 * @throws {Error} On empty versions array
 * @throws {Error} On invalid versions
 */
export function getHighestVersion(versions: string[]) {
  if (!versions.length) {
    throw new Error("Must provide non empty array");
  }
  return versions.sort((aVersion, bVersion) => {
    const aSem = semver.coerce(aVersion);
    const bSem = semver.coerce(bVersion);

    if (!aSem || !bSem) {
      throw new Error(
        `Must provide valid versions (received ${aSem ? bVersion : aVersion})`,
      );
    }

    return semver.gt(aSem, bSem) ? 1 : -1;
  })[versions.length - 1];
}
