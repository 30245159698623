import { QueryDocumentSnapshot } from "@firebase/firestore";
import {
  collection,
  doc,
  DocumentData,
  DocumentReference,
  Firestore,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { UserType } from "../../utilities/contexts/auth/model/User.ts";
import { ParticipantRepository } from "./interface";
import { Participant } from "./model/Participant.ts";

interface FirebaseParticipant
  extends DocumentData,
    Omit<
      Participant,
      "id" | "associatedTeamIds" | "associatedOrganisationIds" | "type"
    > {
  associatedTeams: DocumentReference[];
  associatedOrganisations: DocumentReference[];
  type: string;
}

class ParticipantConverter {
  private readonly firestore: Firestore;

  constructor(firestore: Firestore) {
    this.firestore = firestore;
  }

  toFirestore(participant: Participant): FirebaseParticipant {
    const teamDocs = (participant.associatedTeamIds ?? []).map((id) => {
      return doc(this.firestore, "teams", id);
    });

    const organisationDocs = (participant.associatedOrganisationIds ?? []).map(
      (id) => {
        return doc(this.firestore, "organisations", id);
      },
    );

    return {
      type: participant.type ?? "",
      associatedTeams: teamDocs,
      associatedOrganisations: organisationDocs,
    } as FirebaseParticipant;
  }

  fromFirestore(
    snapshot: QueryDocumentSnapshot<FirebaseParticipant>,
  ): Participant {
    const snapshotData = snapshot.data();

    return {
      email: snapshot.id,
      associatedTeamIds: (snapshotData.associatedTeams ?? []).map(
        (ref: { id: string }) => {
          return ref.id;
        },
      ),
      associatedOrganisationIds: (
        snapshotData.associatedOrganisations ?? []
      ).map((ref: { id: string }) => {
        return ref.id;
      }),
      type:
        snapshotData?.type === ""
          ? undefined
          : (snapshotData?.type as UserType),
    };
  }
}

export class FirebaseParticipantRepository implements ParticipantRepository {
  private readonly firestore: Firestore;
  private converter: ParticipantConverter;

  constructor(firestore: Firestore) {
    this.firestore = firestore;
    this.converter = new ParticipantConverter(firestore);
  }

  async save(participant: Participant): Promise<void> {
    const partDoc = doc(
      this.firestore,
      "participants",
      participant.email,
    ).withConverter(this.converter);

    await setDoc(partDoc, participant);
  }

  async fetchByTeamId(teamId: string): Promise<Participant[]> {
    const participantsCollection = collection(this.firestore, "participants");

    const team = doc(this.firestore, "teams", teamId);

    const participantsQuery = query(
      participantsCollection,
      where("associatedTeams", "array-contains", team),
    );
    const participantsSnapshot = await getDocs(
      participantsQuery.withConverter(this.converter),
    );

    return participantsSnapshot.docs.map((item) => item.data());
  }

  async fetchByEmail(email: string): Promise<Participant | null> {
    const participant = await getDoc(
      doc(this.firestore, "participants", email).withConverter(this.converter),
    );

    if (!participant.exists()) {
      return null;
    }

    return participant.data();
  }
}
