import {
  collection,
  doc,
  DocumentData,
  Firestore,
  getDocs,
  query,
  setDoc,
} from "firebase/firestore";
import { z } from "zod";
import { ModuleRepository } from "./interface.ts";
import {
  Module,
  ModuleQuestion,
  ModuleQuestionSchema,
  ModuleVersion,
  ModuleVersionSchema,
} from "./model/module.ts";

const FirebaseModuleQuestionSchema = ModuleQuestionSchema.omit({
  id: true,
}).and(
  z.object({
    id: z.object({ id: z.string() }),
  }),
);
const FirebaseModuleVersionSchema = ModuleVersionSchema.omit({
  questions: true,
}).and(
  z.object({
    questions: z.array(FirebaseModuleQuestionSchema),
  }),
);
type FirebaseModuleQuestion = z.infer<typeof FirebaseModuleQuestionSchema>;
type FirebaseModuleVersion = z.infer<typeof FirebaseModuleVersionSchema>;

interface FirebaseSnapshot extends DocumentData {
  id: string;
  versions: Record<string, FirebaseModuleVersion>;
}

type FirebaseGotFragment = FirebaseSnapshot[];

export class FirebaseModuleRepository implements ModuleRepository {
  private readonly firestore: Firestore;

  constructor(firestore: Firestore) {
    this.firestore = firestore;
  }

  async save(module: Module): Promise<Module> {
    const { id, ...m } = module;

    const versions = Object.fromEntries(
      Object.entries(m.versions).map((moduleVersion) =>
        this.fromModuleVersionToFirebase(moduleVersion),
      ),
    );

    const moduleData = {
      id,
      versions,
    };

    await setDoc(doc(this.firestore, "modules", id), moduleData);
    return module;
  }

  async fetchAll(): Promise<Module[]> {
    const refs = await getDocs(query(collection(this.firestore, "modules")));
    const modules: FirebaseSnapshot[] = refs.docs.map((m) => {
      return { ...m.data(), id: m.ref.id };
    }) as FirebaseGotFragment;

    return modules.map((item) => this.fromFirebaseSnapshotsToModules(item));
  }

  private fromFirebaseSnapshotsToModules(modules: FirebaseSnapshot): Module {
    return {
      ...modules,
      versions: Object.fromEntries(
        Object.entries(modules.versions ?? {}).map((moduleVersion) =>
          this.fromFirebaseToModuleVersion(moduleVersion),
        ),
      ),
    };
  }

  private fromModuleVersionToFirebase([key, value]: [string, ModuleVersion]): [
    string,
    FirebaseModuleVersion,
  ] {
    const firebaseModuleVersion = {
      ...value,
      questions: value.questions.map((moduleQuestion) =>
        this.fromModuleQuestionToFirebase(moduleQuestion),
      ),
    };

    return [key, firebaseModuleVersion];
  }

  private fromModuleQuestionToFirebase(
    question: ModuleQuestion,
  ): FirebaseModuleQuestion {
    return {
      ...question,
      id: doc(this.firestore, "questions", question.id),
    };
  }

  private fromFirebaseToModuleVersion([key, value]: [
    string,
    FirebaseModuleVersion,
  ]): [string, ModuleVersion] {
    const firebaseModuleVersion = {
      ...value,
      questions: value.questions.map((moduleQuestion) =>
        this.fromFirebaseModuleQuestion(moduleQuestion),
      ),
    };

    return [key, firebaseModuleVersion];
  }

  private fromFirebaseModuleQuestion(
    question: FirebaseModuleQuestion,
  ): ModuleQuestion {
    return {
      ...question,
      id: question.id.id,
    };
  }
}
