import { FC } from "react";
import { AuthUser } from "../../utilities/contexts/auth/model/User.ts";

function getNameInitials(user: AuthUser) {
  return user.displayName
    ?.split(/\s/)
    .filter((name) => name.length >= 1)
    .map((name) => name[0])
    .join("");
}

function getEmailInitials(user: AuthUser) {
  return user.email
    ?.split("@")
    .find(() => true)
    ?.split(/\W/)
    .filter((name) => name.length >= 1)
    .map((name) => name[0])
    .join("");
}

interface AvatarIconProps {
  readonly user?: AuthUser;
}
export const AvatarIcon: FC<AvatarIconProps> = ({ user }: AvatarIconProps) => {
  let initials = undefined;

  if (user) {
    initials = getNameInitials(user);
  }

  if (user && !initials) {
    initials = getEmailInitials(user);
  }

  return (
    <div
      aria-label="Your Avatar"
      className="placeholder avatar tooltip tooltip-bottom tooltip-secondary font-bold uppercase text-secondary-content focus:tooltip-open"
      data-tip={user ? user.displayName ?? user.email : undefined}
    >
      <div className="mx-2 w-10 rounded-full bg-secondary text-secondary-content">
        <span>{initials ?? ""}</span>
      </div>
    </div>
  );
};
