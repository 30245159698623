import { FC } from "react";
import Title from "../../components/typography/Title";
import { ExpandableDetails } from "../admin/modals/ExpandableDetails.tsx";

const FaqPage: FC = () => (
  <div className="prose mx-auto w-full">
    <Title>Frequently Asked Questions</Title>
    <ExpandableDetails title="What do we need to start the process?">
      <p>
        We will start off with a 30 minute meet and greet with all of the team
        leads that will be involved in the process and talk about the delivery
        approach in more detail. There we’ll gather the following information to
        start the process:
      </p>
      <ul>
        <li>What’s the goal/purpose of the department?</li>
        <li>How many teams are there in your department?</li>
        <li>
          How many of those teams are responsible for configuring, building, and
          maintaining software?
        </li>
        <li>What are the names of the teams?</li>
        <li>Who are the points of contact in that team?</li>
        <li>Along which boundaries are the teams divided/organised?</li>
      </ul>
      We will then do the same with all the members of each team to introduce
      them to the Insights process and have them ask questions or share any
      thoughts they may have. This really helps with engagement and
      psychological safety.{" "}
    </ExpandableDetails>

    <ExpandableDetails title="Who do you share our data with?">
      <p>
        At the end of the Insight, we meet with the team and walk them through
        their Team Replay Report with their survey results and some next steps
        and recommendations. They also get a copy of their Discovery Session
        whiteboard.
      </p>

      <p>
        If we are running Insights across multiple teams, the senior leaders
        will also get a Department Replay Report where we highlight any themes
        across all of the teams and collate all the data that we had gathered.
      </p>
    </ExpandableDetails>

    <ExpandableDetails title="My team is new. Is that OK?">
      <p>
        We don’t recommend teams newer than 3 months to participate in Insights.
        Many teams have not created a strong workflow pattern or have shared
        knowledge of what’s going on, yet.
      </p>
    </ExpandableDetails>

    <ExpandableDetails title="How do we make teams feel safe?">
      <p>
        We make sure to have a Meet and Greet with all of the teams prior to us
        sending out our survey. This is to explain the process to them and how
        the data will be used. All of our surveys and sessions are kept
        anonymous with lots of reminders throughout the process. When sharing
        the data in the Department Replay Report, data across the teams is
        collated in a way that doesn’t set itself up for comparison while still
        highlighting problematic areas across all of the teams.
      </p>

      <p>
        If we feel psychological safety is an issue, team names may be
        anonymized and 1:1s with the team members may be needed to understand
        the situation further.
      </p>
    </ExpandableDetails>

    <ExpandableDetails title="What tools do we need to have access to?">
      <p>
        We are a remote-first company and curently use a variety of third-party
        tooling to provide our survey and run our Discovery Sessions:
      </p>

      <ul>
        <li>Web access to Survey Monkey</li>
        <li>Web access to Miro</li>
        <li>Remote conferencing tool (ex. Zoom, Google Meet, Teams)</li>
      </ul>

      <p>
        We are able to consider different tooling but you may incur an
        additional setup fee.
      </p>
    </ExpandableDetails>

    <ExpandableDetails title="How long is this process?">
      <p>
        Each team needs to have about 4.5 hours total time with us and is
        generally done within a week or so (depending how quickly the team gets
        their survey done and session availability).
      </p>
      <ul>
        <li>30 minute survey</li>
        <li>30 minute team Meet and Greet</li>
        <li>3 hour Discovery Session</li>
        <li>30 minute Team Replay Session</li>
      </ul>
      <p>
        If you have a Department Replay Report, the replay session is an
        additional one time 1.5 hours and is delivered around 1-2 weeks
        (depending on the number of teams) after the last team Discovery Session
        is complete.
      </p>
    </ExpandableDetails>

    <ExpandableDetails title="New teamies. Leaving teamies. Do they participate?">
      <p>
        Yes! We love having new team members and team members that are planning
        to leave soon participate in Insights We actually prefer and insist they
        join. These people can give us some of the best insights into the team.
      </p>
    </ExpandableDetails>

    <ExpandableDetails title="Who is part of the team?">
      <p>
        Teams come in all shapes and sized, but our workshop and exercises are
        currently made for a single software delivery team. This allows us to be
        effecient with our time and allow us to explore more deeply with your
        teams.
      </p>
      <p>
        At the most fundamental level, the researchers sought to distinguish a
        “work group” from a “team:”
      </p>

      <ul>
        <li>
          <strong>Work groups</strong> are characterized by the least amount of
          interdependence. They are based on organizational or managerial
          hierarchy. Work groups may meet periodically to hear and share
          information.
        </li>
        <li>
          <strong>Teams</strong> are highly interdependent - they plan work,
          solve problems, make decisions, and review progress in service of a
          specific project. Team members need one another to get work done.
        </li>
      </ul>

      <p>
        The team should respond to the survey and attend the Discovery Session.
        Here’s some tips that will help determine who is a part of what we are
        considering a team:
      </p>

      <ul>
        <li>
          These are people who attend the daily stand-up. To be more specific
          this would likely be the product owner, team/tech lead and the
          developers.
        </li>
        <li>
          No one in the process should be responsible for the performance
          reviews of the team members.
        </li>
        <li>
          The team members should be rallied around the same workflows. If there
          are team members with a unique workflow, you may need to consider
          splitting them for this process. Grouping multiple software teams will
          not make this process any more streamlined. It will actually do the
          opposite.
        </li>
        <li>
          This should be the core team for the team members. If the person works
          across multiple teams (for example, a traditional Product Manager),
          they should not partake in this process.
        </li>
      </ul>
    </ExpandableDetails>

    <ExpandableDetails title="Does the discovery session have to be 3 hours?">
      <p>
        At this time, we only have 3 hour discovery sessions with 2 10 minute
        breaks. We do need the full time to complete all of our exercises to
        have an effective holistic view of the team. If the engagement is high,
        we can sometimes finish early and happily give them their time back.
      </p>
    </ExpandableDetails>
  </div>
);

export default FaqPage;
