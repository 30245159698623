import { createContext, PropsWithChildren, useContext } from "react";
import { InMemoryModuleRepository } from "../../repositories/module/inMemoryModuleRepository.ts";
import { ModuleRepository } from "../../repositories/module/interface.ts";
import { InMemoryOrganisationRepository } from "../../repositories/organisation/inMemoryOrganisationRepository";
import { OrganisationRepository } from "../../repositories/organisation/interface";
import { InMemoryParticipantRepository } from "../../repositories/participant/inMemoryParticipantRepository";
import { ParticipantRepository } from "../../repositories/participant/interface";
import { InMemoryQuestionRepository } from "../../repositories/question/inMemoryQuestionRepository";
import { QuestionRepository } from "../../repositories/question/interface";
import { InMemorySessionsRepository } from "../../repositories/session/inMemorySessionsRepository";
import { SessionRepository } from "../../repositories/session/interface";
import { InMemorySurveyDefinitionRepository } from "../../repositories/surveyDefinition/inMemorySurveyDefinitionRepository";
import { SurveyDefinitionRepository } from "../../repositories/surveyDefinition/interface";
import { InMemorySurveyResponseRepository } from "../../repositories/surveyResponse/inMemorySurveyResponseRepository";
import { SurveyResponseRepository } from "../../repositories/surveyResponse/interface";
import { InMemorySurveyResultsRepository } from "../../repositories/surveyResult/inMemorySurveyResultsRepository";
import { SurveyResultRepository } from "../../repositories/surveyResult/interface";
import { InMemoryTeamRepository } from "../../repositories/team/inMemoryTeamRepository";
import { TeamRepository } from "../../repositories/team/interface";

export interface Repositories {
  orgRepo: OrganisationRepository;
  teamRepo: TeamRepository;
  participantRepo: ParticipantRepository;
  sessionRepo: SessionRepository;
  surveyDefinitionRepo: SurveyDefinitionRepository;
  questionRepo: QuestionRepository;
  surveyResultRepo: SurveyResultRepository;
  surveyResponseRepo: SurveyResponseRepository;
  moduleRepo: ModuleRepository;
}

export const makeMemoryRepos = (): Repositories => ({
  orgRepo: new InMemoryOrganisationRepository(),
  teamRepo: new InMemoryTeamRepository(),
  participantRepo: new InMemoryParticipantRepository(),
  sessionRepo: new InMemorySessionsRepository(),
  surveyDefinitionRepo: new InMemorySurveyDefinitionRepository(),
  questionRepo: new InMemoryQuestionRepository(),
  surveyResultRepo: new InMemorySurveyResultsRepository(),
  surveyResponseRepo: new InMemorySurveyResponseRepository(),
  moduleRepo: new InMemoryModuleRepository(),
});

export const RepositoryContext = createContext<Repositories>(makeMemoryRepos());

export const RepositoryProvider = ({
  repositories,
  children,
}: PropsWithChildren<{ readonly repositories: Repositories }>) => {
  return (
    <RepositoryContext.Provider value={repositories}>
      {children}
    </RepositoryContext.Provider>
  );
};

export function useRepositories(): Repositories {
  return useContext(RepositoryContext);
}
