import { FirebaseApp, initializeApp } from "firebase/app";
import { Auth, connectAuthEmulator, getAuth } from "firebase/auth";
import {
  connectFirestoreEmulator,
  Firestore,
  getFirestore,
} from "firebase/firestore";
import {
  connectStorageEmulator,
  FirebaseStorage,
  getStorage,
} from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: import.meta.env?.VITE_FIREBASE_API_KEY as string,
  authDomain: import.meta.env?.VITE_FIREABSE_AUTH_DOMAIN as string,
  projectId: import.meta.env?.VITE_FIREBASE_PROJECT_ID as string,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET as string,
  messagingSenderId: import.meta.env
    .VITE_FIREBASE_MESSAGING_SENDER_ID as string,
  appId: import.meta.env.VITE_FIREBASE_APP_ID as string,
  measurementId: import.meta.env.VITE_ANALYTICS_MEASUREMENT_ID as string,
};

export function initFirebase(
  appName: string | undefined = undefined,
  emulators = false,
): {
  app: FirebaseApp;
  auth: Auth;
  firestore: Firestore;
  storage: FirebaseStorage;
} {
  // Initialize Firebase
  const app = initializeApp(firebaseConfig, appName);
  const auth = getAuth(app);
  const firestore = getFirestore(app);
  const storage = getStorage(app);

  // Emulators
  if (
    emulators ||
    (["development"].includes(import.meta.env.MODE || "") &&
      import.meta.env.VITE_REPOSITORY_MODE !== "real-firebase-services")
  ) {
    connectAuthEmulator(auth, "http://127.0.0.1:9099");
    connectFirestoreEmulator(firestore, "localhost", 8080);
    connectStorageEmulator(storage, "localhost", 9199);
  }

  return { firestore, auth, storage, app };
}
