import { QueryDocumentSnapshot } from "@firebase/firestore";
import {
  collection,
  doc,
  DocumentData,
  Firestore,
  getDoc,
  getDocs,
  query,
  setDoc,
} from "firebase/firestore";
import { SurveyDefinitionRepository } from "./interface";
import {
  NewSurveyDefinition,
  SurveyDefinition,
  SurveyDefinitionSchema,
} from "./model/SurveyDefinition.ts";

interface FirebaseSurveyDefinition
  extends DocumentData,
    Omit<SurveyDefinition, "id"> {}

class SurveyDefinitionConverter {
  toFirestore(surveyResponse: SurveyDefinition): FirebaseSurveyDefinition {
    return Object.fromEntries(
      Object.entries(surveyResponse).filter(function ([key]) {
        return key !== "id";
      }),
    ) as Omit<SurveyDefinition, "id">;
  }

  fromFirestore(
    snapshot: QueryDocumentSnapshot<
      FirebaseSurveyDefinition,
      FirebaseSurveyDefinition
    >,
  ): SurveyDefinition {
    const snapshotData = snapshot.data();

    try {
      return SurveyDefinitionSchema.parse({
        ...snapshotData,
        id: snapshot.id,
      });
    } catch (e: unknown) {
      console.warn(
        "Saw a survey definition I couldn't parse",
        String(e),
        snapshotData,
      );
      return { ...snapshotData, id: snapshot.id } as SurveyDefinition;
    }
  }
}

export class FirebaseSurveyDefinitionRepository
  implements SurveyDefinitionRepository
{
  private firestore: Firestore;
  private converter: SurveyDefinitionConverter;
  constructor(firestore: Firestore) {
    this.firestore = firestore;
    this.converter = new SurveyDefinitionConverter();
  }

  async fetchAll(): Promise<SurveyDefinition[]> {
    const q = query(collection(this.firestore, "surveyjs-definitions"));
    const snapshot = await getDocs(q.withConverter(this.converter));

    return snapshot.docs.map((doc): SurveyDefinition => {
      return doc.data();
    });
  }

  async fetchById(id: string): Promise<SurveyDefinition | null> {
    const d = await getDoc(
      doc(this.firestore, "surveyjs-definitions", id).withConverter(
        this.converter,
      ),
    );

    if (!d.exists()) {
      return null;
    }
    return d.data();
  }

  async save(
    surveyDefinition: NewSurveyDefinition,
    surveyId: string,
    version: string,
  ): Promise<SurveyDefinition> {
    const id = `${surveyId}_${version}`;
    await setDoc(
      doc(this.firestore, "surveyjs-definitions", id).withConverter(
        this.converter,
      ),
      surveyDefinition,
    );
    return { ...surveyDefinition, id };
  }
}
