import { useEffect, useState } from "react";
import * as semver from "semver/preload";
import {
  Module,
  ModuleVersion,
} from "../../../repositories/module/model/module.ts";
import { useAllModules } from "./useAllModules.tsx";

export function useLatestModuleVersions():
  | Record<Module["id"], ModuleVersion>
  | undefined {
  const modules = useAllModules();
  const [latestModuleVersions, setLatestModuleVersions] = useState<
    Record<Module["id"], ModuleVersion> | undefined
  >(undefined);

  useEffect(() => {
    if (!modules) {
      return;
    }

    const vers = Object.values(modules).flatMap((m) => {
      const entries = Object.entries(m.versions).sort(
        ([aVersion], [bVersion]) => {
          const aSem = semver.coerce(aVersion) ?? "0.0.0";
          const bSem = semver.coerce(bVersion) ?? "0.0.0";

          return semver.gt(aSem, bSem) ? 1 : -1;
        },
      );

      const entryElement = entries[entries.length - 1][1];
      return [[m.id, entryElement]];
    });

    setLatestModuleVersions(
      Object.fromEntries(vers) as Record<Module["id"], ModuleVersion>,
    );
  }, [modules]);

  return latestModuleVersions;
}
