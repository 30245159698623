import { FC, PropsWithChildren } from "react";

type TitleProps = PropsWithChildren<{
  readonly styleClass?: string;
}>;

const Title: FC<TitleProps> = ({ children, styleClass = "" }) => (
  <h2 className={`text-3xl font-bold ${styleClass}`}>{children}</h2>
);
export default Title;
