import { onCLS, onFCP, onFID, onLCP, onTTFB } from "web-vitals/attribution";

const reportWebVitals = () => {
  onCLS(console.debug);
  onFID(console.debug);
  onFCP(console.debug);
  onLCP(console.debug);
  onTTFB(console.debug);
};

export default reportWebVitals;
