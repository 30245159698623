import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { Outlet, useNavigate } from "react-router-dom";
import {
  AuthContext,
  IAuth,
} from "../../utilities/contexts/auth/firebaseAuthProvider.tsx";
import LoadingSpinner from "../layout/landmarks/LoadingSpinner.tsx";
import { SignedIn } from "./SignedIn";
import { SignedOut } from "./SignedOut";

type RequireSignInProps = PropsWithChildren;
const RequireSignIn: FC<RequireSignInProps> = ({ children }) => {
  const { user, onEmailVerifiedUpdate }: IAuth = useContext(AuthContext);

  useEffect(() => {
    // Read params from URL and sign in with them for testing purposes. Used in the e2e tests.
    if (import.meta.env.MODE === "development") {
      if (["localhost", "127.0.0.1"].includes(window.location.hostname)) {
        const currentURL = new URL(window.location.href);
        const testLoginParam = currentURL.searchParams.get("test-login");

        if (testLoginParam) {
          const [email, password] = testLoginParam.split(":");
          void (async () => {
            await signInWithEmailAndPassword(getAuth(), email, password);
          })();
        }
      }
    }
  }, []);

  const nav = useNavigate();
  const handleSignIn = useCallback(() => nav("/sign-in"), [nav]);
  const confirmedSignedOut = true;

  useEffect(() => {
    if (!user?.emailVerified) {
      const refreshCheck = setInterval(() => {
        onEmailVerifiedUpdate(() => clearInterval(refreshCheck));
      }, 1000);

      return () => clearInterval(refreshCheck);
    }

    return () => {
      // so the interface is the same
    };
  }, [onEmailVerifiedUpdate, user?.emailVerified]);

  if (user) {
    return <SignedIn user={user}>{children ? children : <Outlet />}</SignedIn>;
  } else if (confirmedSignedOut) {
    return <SignedOut handleSignIn={handleSignIn} />;
  } else {
    return <LoadingSpinner />;
  }
};

export default RequireSignIn;
