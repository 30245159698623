import { QueryDocumentSnapshot } from "@firebase/firestore";
import {
  collection,
  doc,
  DocumentData,
  DocumentReference,
  Firestore,
  getDoc,
  getDocs,
  query,
  setDoc,
  Timestamp,
  where,
} from "firebase/firestore";
import { SessionRepository } from "./interface";
import { Session, SessionSchema } from "./model/Session.ts";

interface FirebaseSession extends DocumentData {
  status: string;
  event: {
    name: string;
    uuid: string;
    bookedByName: string;
    bookedByEmail: string;
    attendees?: DocumentReference[];
    trelloCardId: string;
    trelloChecklistId: string | null;
    miroBoardId: string | null;
    assignedAkTeam: string | null;
    startTime: Timestamp;
    endTime: Timestamp;
  };
  organisation: DocumentReference | null;
  team: DocumentReference | null;
}

class SessionConverter {
  private readonly firestore: Firestore;

  constructor(firestore: Firestore) {
    this.firestore = firestore;
  }

  toFirestore(session: Session): FirebaseSession {
    const {
      event: { attendeesIds, ...fe },
      teamId,
      organisationId,
      ...fs
    } = session;

    const organisation = organisationId
      ? doc(this.firestore, "organisations", organisationId)
      : null;
    const team = teamId ? doc(this.firestore, "teams", teamId) : null;
    const attendees =
      attendeesIds?.map((a) => {
        return doc(this.firestore, "participants", a);
      }) ?? [];

    return {
      ...fs,
      event: {
        ...fe,
        attendees,
        trelloChecklistId: fe.trelloChecklistId ?? null,
        miroBoardId: fe.miroBoardId ?? null,
        startTime: Timestamp.fromDate(fe.startTime),
        endTime: Timestamp.fromDate(fe.endTime),
        assignedAkTeam: fe.assignedAkTeam ?? null,
      },
      organisation,
      team,
    };
  }

  fromFirestore(
    snapshot: QueryDocumentSnapshot<FirebaseSession, FirebaseSession>,
  ): Session {
    const snapshotData = snapshot.data();

    const {
      event: { attendees, ...fe },
      team,
      organisation,
      ...fs
    } = snapshotData;

    return SessionSchema.parse({
      ...fs,
      event: {
        ...fe,
        attendeesIds: attendees?.map((a) => a.id) ?? [],
        startTime: fe?.startTime?.toDate(),
        endTime: fe.endTime?.toDate(),
      },
      organisationId: organisation?.id ?? null,
      teamId: team?.id ?? null,
    });
  }
}

export class FirebaseSessionRepository implements SessionRepository {
  private firestore: Firestore;
  private sessionConverter: SessionConverter;

  constructor(firestore: Firestore) {
    this.firestore = firestore;
    this.sessionConverter = new SessionConverter(firestore);
  }

  async save(session: Session): Promise<void> {
    await setDoc(
      doc(this.firestore, "sessions", session.event.uuid).withConverter(
        this.sessionConverter,
      ),
      session,
    );
  }

  async fetchByUUID(uuid: string): Promise<Session | null> {
    return (
      (
        await getDoc(
          doc(this.firestore, "sessions", uuid).withConverter(
            this.sessionConverter,
          ),
        )
      ).data() ?? null
    );
  }

  async fetchByTeamIDAndType(teamId: string, name: string): Promise<Session[]> {
    const snapshot = await getDocs(
      query(
        collection(this.firestore, "sessions"),

        where("team", "==", doc(this.firestore, "teams", teamId)),
        where("event.name", "==", name),
      ).withConverter(this.sessionConverter),
    );
    return snapshot.docs.map((docSnapshot) => docSnapshot.data());
  }
}
