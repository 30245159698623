import { FC, PropsWithChildren, useContext } from "react";
import { Outlet } from "react-router-dom";
import {
  AuthContext,
  AuthStatus,
} from "../../../utilities/contexts/auth/firebaseAuthProvider.tsx";
import RequireSignIn from "../../auth/RequireSignIn.tsx";
import Layout from "./Layout.tsx";

type LoginRequiredLayoutProps = PropsWithChildren<{
  readonly uncontained?: boolean;
}>;
export const LoginRequiredLayout: FC<LoginRequiredLayoutProps> = ({
  children,
  uncontained = false,
}) => {
  const { authStatus } = useContext(AuthContext);

  if (authStatus !== AuthStatus.SignedIn) {
    return (
      <Layout center>
        <RequireSignIn>{children ? children : <Outlet />}</RequireSignIn>
      </Layout>
    );
  }

  return (
    <Layout navigation uncontained={uncontained}>
      <RequireSignIn>{children ? children : <Outlet />}</RequireSignIn>
    </Layout>
  );
};
