import { PropsWithChildren, useId, useRef } from "react";

type ExpandableDetailsProps = PropsWithChildren<{
  readonly title: string;
}>;
export const ExpandableDetails = ({
  children,
  title,
}: ExpandableDetailsProps) => {
  const labelId = useId();
  const detailsRef = useRef(null);
  return (
    <details
      aria-labelledby={labelId}
      className="collapse collapse-arrow"
      ref={detailsRef}
    >
      <summary className="collapse-title px-0" id={labelId} role="button">
        <span>{title.length > 0 ? title : "Untitled"}</span>
      </summary>
      <span className="collapse-content">{children}</span>
    </details>
  );
};
