import * as Sentry from "@sentry/react";
import {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from "react";
import { firebaseAuthHelpers } from "../../services";
import { useRepositories } from "../repositoryProvider.tsx";
import { User } from "./model/User.ts";

export enum AuthStatus {
  Loading,
  SignedIn,
  SignedOut,
}

export interface AuthToken {
  token: string | undefined;
  claims: {
    admin?: boolean;
  };
}

export interface IAuth {
  authStatus: AuthStatus;
  signInWithEmail: (email: string, password: string) => Promise<void>;
  verifyEmail: () => Promise<void>;
  googleAuthSignIn: () => Promise<void>;
  sendPasswordlessEmailLink: (email: string) => Promise<void>;
  passwordlessEmailLinkSignIn: (email: string) => Promise<void>;
  isMagicEmailLink: () => boolean;
  signUpWithEmail: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  sendPasswordReset: (email: string) => Promise<void>;
  getUserToken: () => Promise<Omit<AuthToken, "token"> & { token?: string }>;
  onEmailVerifiedUpdate: (callback: CallableFunction) => void;
  user: User | null;
}

const defaultState: IAuth = {
  authStatus: AuthStatus.Loading,
  user: null,
  signInWithEmail: async () => {
    // Implemented below
  },
  verifyEmail: async () => {
    // Implemented below
  },
  googleAuthSignIn: async () => {
    // Implemented below
  },
  sendPasswordlessEmailLink: async () => {
    // Implemented below
  },
  passwordlessEmailLinkSignIn: async () => {
    // Implemented below
  },
  isMagicEmailLink: () => false,
  signUpWithEmail: async () => {
    // Implemented below
  },
  signOut: async () => {
    // Implemented below
  },
  sendPasswordReset: async () => {
    // Implemented below
  },
  getUserToken: () => {
    return Promise.resolve({ token: "", claims: { admin: false } });
  },
  onEmailVerifiedUpdate: (callback) => {
    callback();
  },
};
export const AuthContext = createContext(defaultState);

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const { participantRepo } = useRepositories();
  const [user, setUser] = useState<User | null>(null);
  const [authStatus, setAuthStatus] = useState(AuthStatus.Loading);

  useEffect(() => {
    firebaseAuthHelpers.onAuthStateChanged(({ user: authUser }) => {
      const email = authUser?.email;
      if (authUser && email) {
        setUser({
          displayName: authUser.displayName,
          email: email,
          emailVerified: authUser.emailVerified,
          uid: authUser.uid,
        });
        setAuthStatus(AuthStatus.SignedIn);
        void participantRepo.fetchByEmail(email).then((participant) => {
          setUser({
            displayName: authUser.displayName,
            email: email,
            emailVerified: authUser.emailVerified,
            uid: authUser.uid,
            associatedOrganisationIds:
              participant?.associatedOrganisationIds ?? [],
            associatedTeamIds: participant?.associatedTeamIds ?? [],
            type: participant?.type,
          });
        });
      } else {
        setUser(null);
        setAuthStatus(AuthStatus.SignedOut);
      }
    });
  }, [setAuthStatus, authStatus, participantRepo]);

  const state: IAuth = useMemo(() => {
    async function signInWithEmail(
      username: string,
      password: string,
    ): ReturnType<IAuth["signInWithEmail"]> {
      await firebaseAuthHelpers.EmailAuthSignIn(username, password);
    }

    async function verifyEmail(): ReturnType<IAuth["verifyEmail"]> {
      return await firebaseAuthHelpers.verifyEmail();
    }

    async function googleAuthSignIn(): ReturnType<IAuth["googleAuthSignIn"]> {
      await firebaseAuthHelpers.GoogleAuthSignIn();
    }

    async function sendPasswordReset(
      email: string,
    ): ReturnType<IAuth["sendPasswordReset"]> {
      return await firebaseAuthHelpers.sendPasswordReset(email);
    }

    async function sendPasswordlessEmailLink(
      email: string,
    ): ReturnType<IAuth["sendPasswordlessEmailLink"]> {
      await firebaseAuthHelpers.SendPasswordlessEmailLink(email);
    }

    async function passwordlessEmailLinkSignIn(
      email: string,
    ): ReturnType<IAuth["passwordlessEmailLinkSignIn"]> {
      await firebaseAuthHelpers.PasswordlessEmailLinkSignIn(email);
    }

    function isMagicEmailLink(): ReturnType<IAuth["isMagicEmailLink"]> {
      return firebaseAuthHelpers.IsMagicEmailLink();
    }

    async function signUpWithEmail(
      email: string,
      password: string,
    ): ReturnType<IAuth["signUpWithEmail"]> {
      await firebaseAuthHelpers.EmailAuthCreateUser(email, password);
    }

    async function signOut(): ReturnType<IAuth["signOut"]> {
      return await firebaseAuthHelpers.signOut();
    }

    async function getUserToken(): ReturnType<IAuth["getUserToken"]> {
      return await firebaseAuthHelpers.getUserToken();
    }

    function onEmailVerifiedUpdate(
      callback: CallableFunction,
    ): ReturnType<IAuth["onEmailVerifiedUpdate"]> {
      if (user && !user.emailVerified) {
        void firebaseAuthHelpers.refreshUser().then((currentUser) => {
          const email = currentUser?.email;
          if (currentUser && currentUser.emailVerified && user && email) {
            void firebaseAuthHelpers.refreshToken(true).then(() => {
              setUser({
                ...user,
                displayName: currentUser.displayName,
                email,
                emailVerified: currentUser.emailVerified,
                uid: currentUser.uid,
              });
            });
            callback();
          }
        });
      }
    }

    return {
      authStatus,
      user,
      signUpWithEmail,
      signInWithEmail,
      verifyEmail,
      googleAuthSignIn,
      sendPasswordlessEmailLink,
      passwordlessEmailLinkSignIn,
      isMagicEmailLink,
      sendPasswordReset,
      signOut,
      getUserToken,
      onEmailVerifiedUpdate,
    };
  }, [authStatus, user]);

  useEffect(() => {
    if (authStatus === AuthStatus.SignedIn) {
      Sentry.setUser({ email: user?.email, id: user?.uid });
    } else if (authStatus === AuthStatus.SignedOut) {
      Sentry.setUser(null);
    }
  }, [authStatus, user?.email, user?.uid]);

  if (authStatus === AuthStatus.Loading) {
    return null;
  }

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};
