import { FC, useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../utilities/contexts/auth/firebaseAuthProvider.tsx";
import { User } from "../../utilities/contexts/auth/model/User.ts";
import Title from "../typography/Title";

interface VerifyEmailComponentProps {
  readonly user: User;
}
export const VerifyEmailComponent: FC<VerifyEmailComponentProps> = ({
  user,
}) => {
  const { verifyEmail } = useContext(AuthContext);
  const [emailSent, setEmailSent] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [innerUser, setInnerUser] = useState(user);
  useEffect(() => {
    setInnerUser(user);
  }, [user]);
  const verifyEmailAddress = useCallback(() => {
    async function extracted() {
      await verifyEmail();
      setEmailSent(true);
      setDisableButton(true);
    }

    void extracted();
  }, [verifyEmail]);

  return (
    <div className="mx-auto">
      {emailSent ? (
        <div className="alert alert-info text-center">
          <div>
            <span>
              A verification email has been sent! This may take a few minutes to
              show up in your inbox.
            </span>
          </div>
        </div>
      ) : null}
      <Title styleClass="mt-10">Please verify your email address</Title>
      <p className="mt-4">
        Before you can use Insights, you need to verify your email address.
      </p>
      <p className="mt-4">
        The button below will send an email to {innerUser.email}. Please click
        the link in the email to verify your email address.
      </p>
      <button
        className={`btn mt-6 ${disableButton ? "btn-disabled" : ""}`}
        onClick={verifyEmailAddress}
        type="button"
      >
        Verify your email address
      </button>
    </div>
  );
};
