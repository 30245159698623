import { ModuleRepository } from "./interface.ts";
import { Module } from "./model/module.ts";

export class InMemoryModuleRepository implements ModuleRepository {
  private data: Module[] = [];

  save(module: Module): Promise<Module> {
    this.data.push(structuredClone(module));
    this.data.sort((a, b) => a.id.localeCompare(b.id));

    return Promise.resolve(structuredClone(module));
  }

  fetchAll(): Promise<Module[]> {
    return Promise.resolve(structuredClone(this.data));
  }
}
