import * as Sentry from "@sentry/react";
import React, { lazy } from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "@fontsource/maven-pro";
import { getFirestore } from "firebase/firestore";
import Layout from "./components/layout/outlet/Layout";
import { LoginRequiredLayout } from "./components/layout/outlet/RequiredSigninLayout";
import { initFirebase } from "./firebase";
import { SurveyPageListPage } from "./pages/admin/survey-page/SurveyPageListPage";
import FaqPage from "./pages/static/FaqPage";
import HomePage from "./pages/static/HomePage";
import { FirebaseModuleRepository } from "./repositories/module/firebaseModuleRepository";
import { FirebaseOrganisationRepository } from "./repositories/organisation/firebaseOrganisationRepository";
import { FirebaseParticipantRepository } from "./repositories/participant/firebaseParticipantRepository";
import { FirebaseQuestionRepository } from "./repositories/question/firebaseQuestionRepository";
import { FirebaseSessionRepository } from "./repositories/session/firebaseSessionRepository";
import { FirebaseSurveyDefinitionRepository } from "./repositories/surveyDefinition/firebaseSurveyDefinitionRepository";
import { FirebaseSurveyResponseRepository } from "./repositories/surveyResponse/firebaseSurveyResponseRepository";
import { FirebaseSurveyResultRepository } from "./repositories/surveyResult/firebaseSurveyResultRepository";
import { FirebaseTeamRepository } from "./repositories/team/firebaseTeamRepository";
import * as authContentHelpers from "./utilities/contexts/auth/firebaseAuthProvider";
import { DataCacheProvider } from "./utilities/contexts/data-cache/dataCacheContext.tsx";
import { RepositoryProvider } from "./utilities/contexts/repositoryProvider";
import { ThemeProvider } from "./utilities/contexts/themeProvider";

const MultiTeamSoftwareAssessment = lazy(
  () => import("./pages/static/MultiTeamSoftwareAssessment"),
);
const FourKeyMetricsPage = lazy(
  () => import("./pages/static/FourKeyMetricsPage"),
);
const TeamTopologiesPage = lazy(
  () => import("./pages/static/TeamTopologiesPage"),
);
const PsychologicalSafetyPage = lazy(
  () => import("./pages/static/PsychologicalSafetyPage"),
);
const EmployeeAdvocacyPage = lazy(
  () => import("./pages/static/EmployeeAdvocacyPage"),
);
const OrganisationListPage = lazy(
  () => import("./pages/admin/organisation-list/OrganisationListPage"),
);
const OrgCulturePage = lazy(() => import("./pages/static/OrgCulturePage.tsx"));
const ParticipantResponsePage = lazy(
  () => import("./pages/admin/participant/ParticipantResponsePage"),
);
const SurveyListPage = lazy(
  () => import("./pages/admin/survey-list/SurveyListPage.tsx"),
);

const CookiePolicy = lazy(
  () => import("./pages/account-management/CookiePolicy"),
);
const MagicLinkSignIn = lazy(
  () => import("./pages/account-management/MagicLinkSignIn"),
);
const PrivacyPolicy = lazy(
  () => import("./pages/account-management/PrivacyPolicy"),
);
const ResetPassword = lazy(
  () => import("./pages/account-management/ResetPassword"),
);
const SignIn = lazy(() => import("./pages/account-management/SignIn"));
const SignUp = lazy(() => import("./pages/account-management/SignUp"));
const BuildSurvey = lazy(() => import("./pages/build-survey/BuildSurvey"));

const TeamStaticResults = lazy(
  () => import("./pages/results/team/TeamStaticResults"),
);
const OrganisationResults = lazy(
  () => import("./pages/results/organisation/OrganisationResults"),
);
const TeamPage = lazy(() => import("./pages/admin/team/TeamPage"));
const TeamListPage = lazy(() => import("./pages/admin/team-list/TeamListPage"));
const Survey = lazy(() => import("./pages/survey/Survey"));
const NotFound = lazy(() => import("./pages/not-found/NotFound"));
const DashboardManager = lazy(
  () => import("./pages/dashboard/DashboardManager"),
);

const AboutPage = lazy(() => import("./pages/static/AboutPage"));

Sentry.init({
  dsn: "https://393e76f7a9c6d9b61f087acdef514afa@o4507334159237120.ingest.de.sentry.io/4507334181126224",
  environment:
    import.meta.env.MODE === "development" ? "development" : "production",
  // Performance Monitoring
  tracePropagationTargets: [
    "localhost",
    "insights-portal.web.app",
    "insights-portal.armakuni.com",
    import.meta.env.VITE_FIREABSE_AUTH_DOMAIN,
    import.meta.env.VITE_SURVEY_BUILDER_ENDPOINT,
    import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  ],
  release: import.meta.env.VITE_SENTRY_RELEASE as string | undefined,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
    Sentry.browserProfilingIntegration(),
  ],
  replaysSessionSampleRate: import.meta.env.MODE === "development" ? 0 : 0.5,
  replaysOnErrorSampleRate: import.meta.env.MODE === "development" ? 0 : 1,
  tracesSampleRate: import.meta.env.MODE === "development" ? 0 : 1,
});
const sentryCreateBrowserRouter =
  import.meta.env.MODE === "development"
    ? createBrowserRouter
    : Sentry.wrapCreateBrowserRouter(createBrowserRouter);

initFirebase();
const router = sentryCreateBrowserRouter([
  {
    path: "/",
    errorElement: (
      <Layout center>
        <NotFound />
      </Layout>
    ),
    children: [
      {
        index: true,
        element: (
          <LoginRequiredLayout uncontained>
            <DashboardManager />
          </LoginRequiredLayout>
        ),
        errorElement: <NotFound />,
      },
      {
        path: "survey",
        element: <LoginRequiredLayout />,
        children: [
          {
            path: ":teamId",
            element: <Survey />,
          },
        ],
      },
      {
        path: "results",
        element: <LoginRequiredLayout />,
        children: [
          {
            path: ":teamId",
            element: <TeamStaticResults />,
          },
        ],
      },
      {
        path: "results-static",
        element: <LoginRequiredLayout />,
        children: [
          {
            path: ":teamId",
            element: <TeamStaticResults />,
          },
        ],
      },
      {
        path: "org-results",
        element: <LoginRequiredLayout />,
        children: [
          {
            path: ":orgId",
            element: <OrganisationResults />,
          },
        ],
      },
      {
        path: "build-survey",
        element: <LoginRequiredLayout />,
        children: [
          {
            index: true,
            element: <BuildSurvey />,
          },
        ],
      },
      {
        path: "admin",
        element: <LoginRequiredLayout />,
        children: [
          {
            path: "pages",
            children: [
              {
                index: true,
                element: <SurveyPageListPage />,
              },
            ],
          },
          {
            path: "organisation",
            children: [
              {
                index: true,
                element: <OrganisationListPage />,
              },

              {
                path: ":orgId",
                element: <TeamListPage />,
              },
            ],
          },
          {
            path: "free",
            children: [
              {
                path: "teams",
                element: <TeamListPage />,
              },
            ],
          },
          {
            path: "team",
            children: [
              {
                path: ":teamId",
                children: [
                  {
                    index: true,
                    element: <TeamPage />,
                  },
                  {
                    path: "participant",
                    children: [
                      {
                        path: ":email",
                        element: <ParticipantResponsePage />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: "survey",
            children: [
              {
                index: true,
                element: <SurveyListPage />,
              },
            ],
          },
        ],
      },
      {
        path: "sign-in",
        element: <Layout center />,
        children: [{ element: <SignIn />, index: true }],
      },
      {
        path: "sign-up",
        element: <Layout center />,
        children: [{ element: <SignUp />, index: true }],
      },
      {
        path: "magic-link",
        element: <Layout center />,
        children: [{ element: <MagicLinkSignIn />, index: true }],
      },
      {
        path: "reset-password",
        element: <Layout center />,
        children: [{ element: <ResetPassword />, index: true }],
      },

      {
        path: "new-home",
        element: <Layout navigation uncontained />,
        children: [{ element: <HomePage />, index: true }],
      },
      {
        path: "about",
        element: <Layout navigation />,
        children: [{ element: <AboutPage />, index: true }],
      },
      {
        path: "org-culture",
        element: <Layout navigation />,
        children: [{ element: <OrgCulturePage />, index: true }],
      },
      {
        path: "psychological-safety",
        element: <Layout navigation />,
        children: [{ element: <PsychologicalSafetyPage />, index: true }],
      },
      {
        path: "enps",
        element: <Layout navigation />,
        children: [{ element: <EmployeeAdvocacyPage />, index: true }],
      },
      {
        path: "4key",
        element: <Layout navigation />,
        children: [{ element: <FourKeyMetricsPage />, index: true }],
      },
      {
        path: "tt",
        element: <Layout navigation />,
        children: [{ element: <TeamTopologiesPage />, index: true }],
      },
      {
        path: "mtsa",
        element: <Layout navigation />,
        children: [{ element: <MultiTeamSoftwareAssessment />, index: true }],
      },
      {
        path: "faq",
        element: <Layout navigation />,
        children: [{ element: <FaqPage />, index: true }],
      },
      {
        path: "cookie-policy",
        element: <Layout navigation />,
        children: [{ element: <CookiePolicy />, index: true }],
      },
      {
        path: "privacy-policy",
        element: <Layout navigation />,
        children: [{ element: <PrivacyPolicy />, index: true }],
      },
      {
        path: "*",
        element: (
          <Layout center>
            <NotFound />
          </Layout>
        ),
      },
    ],
  },
]);

const rootDiv = document.getElementById("root");
if (!rootDiv) {
  throw new Error("This can't be right");
}
const root = ReactDOM.createRoot(rootDiv);
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <RepositoryProvider
        repositories={{
          orgRepo: new FirebaseOrganisationRepository(getFirestore()),
          teamRepo: new FirebaseTeamRepository(getFirestore()),
          participantRepo: new FirebaseParticipantRepository(getFirestore()),
          sessionRepo: new FirebaseSessionRepository(getFirestore()),
          surveyDefinitionRepo: new FirebaseSurveyDefinitionRepository(
            getFirestore(),
          ),
          questionRepo: new FirebaseQuestionRepository(getFirestore()),
          surveyResultRepo: new FirebaseSurveyResultRepository(getFirestore()),
          surveyResponseRepo: new FirebaseSurveyResponseRepository(
            getFirestore(),
          ),
          moduleRepo: new FirebaseModuleRepository(getFirestore()),
        }}
      >
        <DataCacheProvider>
          <authContentHelpers.AuthProvider>
            <RouterProvider router={router} />
          </authContentHelpers.AuthProvider>
        </DataCacheProvider>
      </RepositoryProvider>
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
