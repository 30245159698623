import { Organisation } from "../organisation/model/Organisation.ts";
import { NewTeam, Team } from "./model/Team.ts";

export class TeamDoesNotExistError extends Error {}

export interface TeamRepository {
  /**
   * @throws {TeamDoesNotExistError} on team not found
   */
  fetchById(teamId: string): Promise<Team>;

  /***
   * When null or undefined lists teams not in an organisation
   */
  fetchByOrganisationId(orgId: string | null): Promise<Team[]>;

  /**
   * @throws {Error} on failing to retrieve a team after saving
   */
  save(team: NewTeam, organisation?: Organisation): Promise<Team>;

  fetch(): Promise<Team[]>;
}
