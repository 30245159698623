import { useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import {
  AuthContext,
  AuthStatus,
  IAuth,
} from "../../../utilities/contexts/auth/firebaseAuthProvider.tsx";
import { AvatarIcon } from "../../auth/AvatarIcon";
import { ArmakuniLogomark } from "../../brands/armakuni/ArmakuniLogomark";
import { ThemePicker } from "../../theme/ThemePicker";

export const NavBar = () => {
  const { signOut, user, authStatus }: IAuth = useContext(AuthContext);

  const onSignOut = useCallback(() => {
    async function extracted() {
      await signOut();
    }

    void extracted();
  }, [signOut]);

  return (
    <div className="navbar bg-primary text-primary-content" role="navigation">
      <div className="navbar-start">
        <div className="flex-1">
          <Link className="btn-ghost btn text-xl normal-case" to="/">
            <ArmakuniLogomark className="h-18 w-12" />
            &nbsp;Insights
          </Link>
        </div>
      </div>
      <div className="navbar-end">
        <ThemePicker />
        {user ? (
          <div className="mr-1 flex-none text-center">
            <AvatarIcon user={user || undefined} />
          </div>
        ) : null}

        <div className="flex-none">
          {authStatus === AuthStatus.SignedOut ? (
            <Link className="btn btn-warning" to="/sign-in">
              Sign in
            </Link>
          ) : (
            <button
              className="btn-warning btn "
              onClick={onSignOut}
              type="button"
            >
              Sign out
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
