import { Md5 } from "ts-md5";
import { v4 as uuiv4 } from "uuid";
import { z } from "zod";
import { Organisation } from "../organisation/model/Organisation.ts";
import { TeamDoesNotExistError, TeamRepository } from "./interface";
import { NewTeam, NewTeamSchema, Team, TeamSchema } from "./model/Team.ts";

export class InMemoryTeamRepository implements TeamRepository {
  private readonly datastore: Record<string, Team>;

  constructor() {
    this.datastore = {};
  }

  async save(team: NewTeam, organisation?: Organisation): Promise<Team> {
    const id =
      organisation === undefined
        ? uuiv4()
        : Md5.hashStr([organisation.name, team.name].join("-"));

    // This wil drop undefined keys making it more like db
    const newTeam = await NewTeamSchema.parseAsync(team);

    const savedTeam = {
      ...newTeam,
      id,
      organisationId: organisation?.id ?? null,
    };
    this.datastore[id] = savedTeam;

    return structuredClone(await TeamSchema.parseAsync(savedTeam));
  }

  fetch(): Promise<Team[]> {
    return Promise.resolve(structuredClone(Object.values(this.datastore)));
  }

  async fetchById(teamId: string): Promise<Team> {
    const team = this.datastore[teamId];
    if (!team) {
      return Promise.reject(
        new TeamDoesNotExistError("No team with ID: " + teamId),
      );
    }

    return structuredClone(await TeamSchema.parseAsync(team));
  }

  async fetchByOrganisationId(orgId: string | null): Promise<Team[]> {
    const teamsInOrg = Object.values(this.datastore).filter((team) =>
      ([null] as (string | null)[]).includes(orgId)
        ? team.organisationId === null
        : team.organisationId === orgId,
    );
    return structuredClone(await z.array(TeamSchema).parseAsync(teamsInOrg));
  }
}
