import { v4 } from "uuid";
import { SurveyResponseRepository } from "./interface";
import {
  NewSurveyResponse,
  SurveyResponse,
  SurveyResponseSchema,
} from "./model/SurveyResponse.ts";

export class InMemorySurveyResponseRepository
  implements SurveyResponseRepository
{
  private data: Record<string, SurveyResponse> = {};
  save(
    surveyResponse: SurveyResponse | NewSurveyResponse,
  ): Promise<SurveyResponse> {
    const parsedSurveyResponse = SurveyResponseSchema.safeParse(surveyResponse);
    const id: string = parsedSurveyResponse.success
      ? parsedSurveyResponse.data.id
      : v4();

    this.data[id] = { ...surveyResponse, id };

    return Promise.resolve(structuredClone(this.data[id]));
  }

  fetchByTeamIdAndEmail(
    teamId: string,
    participantEmail: string,
  ): Promise<SurveyResponse | null> {
    return Promise.resolve(
      structuredClone(
        Object.values(this.data).find(
          (response) =>
            response.team === teamId &&
            response.participant === participantEmail,
        ) ?? null,
      ),
    );
  }
}
