import { z } from "zod";

export const SessionEventSchema = z.object({
  name: z.string(),
  uuid: z.string(),
  bookedByName: z.string(),
  bookedByEmail: z.string(),
  attendeesIds: z.array(z.string()).optional(),
  trelloCardId: z.string(),
  trelloChecklistId: z.string().optional(),
  miroBoardId: z.string().optional(),
  assignedAkTeam: z.string().optional(),
  startTime: z.coerce.date(), // Assuming time is represented as string
  endTime: z.coerce.date(), // Assuming time is represented as string
});
export const SessionSchema = z.object({
  status: z.string(),
  event: SessionEventSchema,
  organisationId: z.string().nullable(),
  teamId: z.string().nullable(),
});
export type Session = z.infer<typeof SessionSchema>;
