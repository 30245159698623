import {
  ChangeEventHandler,
  DetailedHTMLProps,
  InputHTMLAttributes,
  PropsWithChildren,
  useCallback,
  useEffect,
  useId,
  useState,
} from "react";
import { z, ZodError, ZodSchema } from "zod";

const doNothing = () => {
  // No operation intentionally
};
const alwaysValid = z.any();
type HTMLInputProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

type Validator = ZodSchema;
export const TextInput = ({
  onChange = doNothing,
  value,
  placeholder,
  label,
  inputType,
  required,
  trailer,
  schema = alwaysValid,
}: {
  readonly onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  readonly value: string | null;
  readonly label: string;
  readonly inputType?: HTMLInputProps["type"];
  readonly required?: HTMLInputProps["required"];
  readonly placeholder?: HTMLInputProps["placeholder"];
  readonly trailer?: PropsWithChildren["children"];
  readonly schema?: Validator;
}) => {
  const id = useId();
  const errorId = useId();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const validate = useCallback(
    (value: string | null) => {
      try {
        if (value !== null && value.length > 0) schema.parse(value);
        setErrorMessage("");
      } catch (error) {
        if (error instanceof ZodError) {
          setErrorMessage(error.issues.map((item) => item.message).join(" ;"));
        }
      }
    },
    [schema],
  );
  const onChangeWithValidator: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (event) => {
        validate(event.target.value);
        onChange(event);
      },
      [onChange, validate],
    );

  useEffect(() => {
    validate(value);
  }, [validate, value]);

  return (
    <div className="form-control transition-all">
      <label className="label" htmlFor={id}>
        {label}
      </label>
      <input
        aria-errormessage={errorMessage ? errorId : undefined}
        aria-invalid={Boolean(errorMessage)}
        className={`transition-all input-bordered input ${errorMessage ? "input-error" : ""}`}
        id={id}
        onBlur={onChangeWithValidator}
        onChange={onChangeWithValidator}
        placeholder={placeholder}
        required={required}
        type={inputType}
        value={value ?? ""}
      />
      <span
        className={`text-error transition-all ${errorMessage ? "visible" : "invisible"}`}
        id={errorMessage ? errorId : undefined}
      >
        {errorMessage ? errorMessage : <>&nbsp;</>}
      </span>
      {trailer ?? null}
    </div>
  );
};
