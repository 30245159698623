import { FC, useCallback } from "react";
import { Theme } from "../../utilities/contexts/themeProvider";

interface ThemeOptionProps {
  readonly theme: Theme;
  readonly selected: Theme;
  readonly icon: string;
  readonly onSelected: (theme: Theme) => void;
}
export const ThemeOption: FC<ThemeOptionProps> = ({
  theme,
  icon,
  selected,
  onSelected,
}: ThemeOptionProps) => {
  const onThemeSelected = useCallback(
    () => onSelected(theme),
    [theme, onSelected],
  );

  return (
    <button
      className={`my-1 rounded-lg hover:bg-neutral hover:text-neutral-content ${
        selected === theme ? "bg-neutral text-neutral-content" : ""
      }`}
      onClick={onThemeSelected}
      type="button"
    >
      <span className="mr-1">{icon}</span>
      <span>{theme}</span>
    </button>
  );
};
