import { Cog8ToothIcon } from "@heroicons/react/24/solid";
import {
  ChangeEventHandler,
  Dispatch,
  FormEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { z } from "zod";
import { CheckboxInput } from "../../../components/forms/CheckboxInput.tsx";
import { TextInput } from "../../../components/forms/TextInput.tsx";
import { useAllModules } from "../../../utilities/contexts/data-cache/useAllModules.tsx";
import { useLatestModuleVersions } from "../../../utilities/contexts/data-cache/useLatestModuleVersions.tsx";
import { useRepositories } from "../../../utilities/contexts/repositoryProvider.tsx";
import { SurveyDefinitionGenerator } from "../../../utilities/services/surveyDefinitionGenerator.ts";
import { Modal } from "./Modal.tsx";

const FormSchema = z.object({
  title: z.string().min(3),
  checkedIds: z.array(z.string()).min(1),
  id: z
    .string()
    .regex(
      /^[0-9a-z-]+$/g,
      "String must only contain lowercase letter, numbers and hyphens",
    ),
});

export const CreateSurveyDefinitionModal = () => {
  const { surveyDefinitionRepo, questionRepo, moduleRepo } = useRepositories();
  const modules = useAllModules();
  const latestModules = useLatestModuleVersions();

  useEffect(() => {
    if (!modules) {
      return;
    }

    setFormData((data) => {
      return {
        ...data,
        checkedIds:
          data.checkedIds.length === 0 ? Object.keys(modules) : data.checkedIds,
      };
    });
  }, [modules]);

  const generator = useMemo(
    () =>
      new SurveyDefinitionGenerator(
        surveyDefinitionRepo,
        moduleRepo,
        questionRepo,
      ),
    [moduleRepo, questionRepo, surveyDefinitionRepo],
  );
  const [formData, setFormData] = useState<z.infer<typeof FormSchema>>({
    title: "",
    id: "",
    checkedIds: [] as string[],
  });

  const handleTitleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const suggestedId = event.target.value
        .toLowerCase()
        .replace(/\s+/g, "-")
        .replace(/[^0-9a-z-]/g, "");
      setFormData((sd) => ({
        ...sd,
        title: event.target.value,
        id: suggestedId,
      }));
    },
    [],
  );

  const handleIdChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setFormData((sd) => ({ ...sd, id: event.target.value }));
    },
    [],
  );

  const handleFormSubmit = useCallback(
    (
      event: FormEvent<HTMLFormElement>,
      setError: Dispatch<SetStateAction<string | null>>,
    ): void => {
      void (async function () {
        const vs = FormSchema.safeParse(formData);
        if (modules && vs.success) {
          await generator.generateSurveyForModules(
            vs.data.id,
            "1.0",
            vs.data.title,
            vs.data.checkedIds.map((id) => modules[id]),
          );
          setError("");
        } else {
          setError(vs?.error?.message ?? "Unknown error occured");
          event.preventDefault();
        }
      })();
    },
    [formData, generator, modules],
  );

  const checkboxChangeHandler: ChangeEventHandler<HTMLInputElement> =
    useCallback((event) => {
      if (event.target.checked) {
        setFormData((data) => {
          return {
            ...data,
            checkedIds: [...data.checkedIds, event.target.value],
          };
        });
      } else {
        setFormData((data) => {
          return {
            ...data,
            checkedIds: data.checkedIds.filter(
              (item) => item !== event.target.value,
            ),
          };
        });
      }
    }, []);
  return (
    <Modal
      modalButtonText={
        <>
          <Cog8ToothIcon aria-hidden className="w-4 h-4" />
          Generate Survey
        </>
      }
      onSubmit={handleFormSubmit}
      title="Generate Survey"
    >
      <TextInput
        inputType="text"
        label="Title"
        onChange={handleTitleChange}
        schema={FormSchema.shape.title}
        value={formData.title}
      />

      <TextInput
        inputType="text"
        label="Id"
        onChange={handleIdChange}
        schema={FormSchema.shape.id}
        value={formData.id}
      />
      {latestModules
        ? Object.entries(latestModules).map(([moduleId, latestModule]) => (
            <CheckboxInput
              key={moduleId}
              label={latestModule.title}
              onChange={checkboxChangeHandler}
              value={moduleId}
              checked={formData.checkedIds.includes(moduleId)}
            />
          ))
        : null}
    </Modal>
  );
};
