import { QueryDocumentSnapshot, SnapshotOptions } from "@firebase/firestore";
import {
  collection,
  doc,
  DocumentData,
  documentId,
  DocumentReference,
  Firestore,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  Timestamp,
  where,
} from "firebase/firestore";
import { Md5 } from "ts-md5";
import { z } from "zod";
import {
  OrganisationRepository,
  OrganisationSaveFailedError,
} from "./interface";
import { NewOrganisation, Organisation } from "./model/Organisation.ts";

interface FirebaseOrganisation extends DocumentData {
  createdDate: Timestamp;
  internalNote: string;
  name: string;
  trelloCardId: string;
  discoveryBookingLink?: string;
}

const organisationConverter = {
  toFirestore(org: Organisation): FirebaseOrganisation {
    const {
      createdDate,
      trelloCardId,
      name,
      internalNote,
      discoveryBookingLink,
    } = org;

    return {
      createdDate: Timestamp.fromDate(createdDate),
      internalNote,
      name,
      trelloCardId,
      discoveryBookingLink,
    };
  },

  /**
   * @throws {Error} If the snapshot data is null (should be impossible)
   */
  fromFirestore(
    snapshot: QueryDocumentSnapshot<FirebaseOrganisation>,
    options: SnapshotOptions,
  ): Organisation {
    const data = snapshot.data(options);
    if (!data) {
      throw new Error("Snapshot data missing!");
    }
    const {
      createdDate,
      internalNote,
      name,
      trelloCardId,
      resultsAvailable,
      discoveryBookingLink,
    } = data;
    return {
      createdDate: createdDate.toDate(),
      internalNote: internalNote,
      name: name,
      trelloCardId: trelloCardId,
      id: snapshot.id,
      resultsAvailable: z.coerce.boolean().parse(resultsAvailable),
      discoveryBookingLink: discoveryBookingLink,
    };
  },
};

export class FirebaseOrganisationRepository implements OrganisationRepository {
  private readonly firestore: Firestore;

  constructor(firestore: Firestore) {
    this.firestore = firestore;
  }

  async find(): Promise<Organisation[]> {
    const c = collection(this.firestore, "organisations").withConverter(
      organisationConverter,
    );
    const snapshot = await getDocs(query(c, orderBy("name")));
    return snapshot.docs.map((firebaseOrg) => firebaseOrg.data());
  }

  async save(
    organisation: NewOrganisation | Organisation,
  ): Promise<Organisation> {
    const reference: DocumentReference = await this.updateOrganisation({
      id: Md5.hashStr(organisation.internalNote),
      ...organisation,
    });
    const data = await this.fetchByOrganisationId(reference.id);
    if (!data) {
      throw new OrganisationSaveFailedError(organisation);
    }

    return data;
  }

  async fetchByOrganisationIds(orgIds: string[]): Promise<Organisation[]> {
    const snapshot = await getDocs(
      query(
        collection(this.firestore, "organisations"),
        where(documentId(), "in", orgIds),
      ).withConverter(organisationConverter),
    );
    return snapshot.docs.map((firebaseOrg) => firebaseOrg.data());
  }

  async fetchByOrganisationId(
    orgId: string,
  ): Promise<Organisation | undefined> {
    const reference = doc(this.firestore, "organisations", orgId).withConverter(
      organisationConverter,
    );

    return (await getDoc(reference)).data();
  }

  private async updateOrganisation(
    existingOrganisation: Organisation,
  ): Promise<DocumentReference> {
    const reference = doc(
      this.firestore,
      "organisations",
      existingOrganisation.id,
    ).withConverter(organisationConverter);
    await setDoc(reference, existingOrganisation);

    return reference;
  }
}
