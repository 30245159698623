import { doc, Firestore, getDoc, setDoc } from "firebase/firestore";
import { SurveyResultRepository } from "./interface";
import { NewSurveyResult, SurveyResult } from "./model/surveyResult";

export class FirebaseSurveyResultRepository implements SurveyResultRepository {
  private firestore: Firestore;
  constructor(firestore: Firestore) {
    this.firestore = firestore;
  }

  async fetchById(id: string): Promise<SurveyResult | null> {
    const d = await getDoc(doc(this.firestore, "survey-results", id));

    if (!d.exists()) {
      return null;
    }

    return { ...d.data().data, id } as SurveyResult;
  }

  async save(surveyResult: NewSurveyResult, id: string): Promise<SurveyResult> {
    await setDoc(doc(this.firestore, "survey-results", id), {
      data: surveyResult,
    });

    return { ...surveyResult, id };
  }
}
