import {
  FormEvent,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useId,
  useRef,
  useState,
} from "react";

export type FormEventHandlerWithError = (
  event: FormEvent<HTMLFormElement>,
  setError: React.Dispatch<React.SetStateAction<string | null>>,
) => ReturnType<React.FormEventHandler<HTMLFormElement>>;
type ModalProps = PropsWithChildren<{
  readonly title: ReactNode;
  readonly onSubmit: FormEventHandlerWithError;
  readonly modalButtonText: ReactNode;
}>;
export const Modal = ({
  children,
  onSubmit,
  title,
  modalButtonText,
}: ModalProps) => {
  const [error, setError] = useState<string | null>(null);
  const modalRef = useRef<HTMLDialogElement>(null);
  const onModalShow = useCallback(() => {
    const currentElement = modalRef.current;

    if (!currentElement) {
      return;
    }

    currentElement.showModal();
  }, []);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      return onSubmit(event, setError);
    },
    [onSubmit],
  );
  const labelId = useId();
  const errorId = useId();

  return (
    <>
      <button
        className="btn-primary btn btn-sm"
        onClick={onModalShow}
        type="button"
      >
        {modalButtonText}
      </button>
      <dialog
        aria-labelledby={labelId}
        className="modal"
        ref={modalRef}
        role="alertdialog"
      >
        <div className="modal-box">
          <h3 className="text-lg font-bold" id={labelId}>
            {title}
          </h3>
          {error ? (
            <div className="alert alert-error" id={errorId}>
              {error}
            </div>
          ) : null}
          {children}

          <div className="mt-4 flex gap-2">
            <form method="dialog" onSubmit={handleSubmit}>
              <button className="btn-primary btn-sm btn" type="submit">
                Save
              </button>
            </form>
            <form method="dialog">
              <button className="btn-secondary btn-sm btn" type="submit">
                Close
              </button>
            </form>
          </div>
        </div>
        <form className="modal-backdrop" method="dialog">
          <button aria-hidden type="submit">
            Close
          </button>
        </form>
      </dialog>
    </>
  );
};
