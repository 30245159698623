import { z } from "zod";

export const MatrixResponseSchema = z.object({
  "select answer": z.number(),
});

export type MatrixResponse = z.infer<typeof MatrixResponseSchema>;

const QuestionResponseSchema = z
  .string()
  .or(z.number())
  .or(MatrixResponseSchema);

export type QuestionResponse = z.infer<typeof QuestionResponseSchema>;

export const SurveyResponseSchema = z.object({
  id: z.string().min(1),
  data: z.record(QuestionResponseSchema),
  timings: z.object({ pages: z.record(z.date()) }).optional(),
  participant: z.string(),
  team: z.string(),
  isComplete: z.coerce.boolean().default(false),
  currentPageNo: z.number().optional(),
});

export type SurveyResponse = z.infer<typeof SurveyResponseSchema>;

export const NewSurveyResponseSchema = SurveyResponseSchema.omit({ id: true });
export type NewSurveyResponse = z.infer<typeof NewSurveyResponseSchema>;
