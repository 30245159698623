import { SurveyDefinitionRepository } from "./interface";
import {
  NewSurveyDefinition,
  SurveyDefinition,
} from "./model/SurveyDefinition.ts";

export class InMemorySurveyDefinitionRepository
  implements SurveyDefinitionRepository
{
  private readonly datastore: SurveyDefinition[];

  constructor() {
    this.datastore = [];
  }
  fetchAll(): Promise<SurveyDefinition[]> {
    return Promise.resolve(structuredClone(this.datastore));
  }

  fetchById(id: string): Promise<SurveyDefinition | null> {
    const value =
      this.datastore.find((sd) => {
        return sd.id === id;
      }) ?? null;

    return Promise.resolve(structuredClone(value));
  }

  save(
    surveyDefinition: NewSurveyDefinition,
    surveyId: string,
    version: string,
  ): Promise<SurveyDefinition> {
    const sd = { ...surveyDefinition, id: `${surveyId}_${version}` };
    this.datastore.push(structuredClone(sd));
    return Promise.resolve(structuredClone(sd));
  }
}
