import { Md5 } from "ts-md5";
import { OrganisationRepository } from "./interface";
import {
  NewOrganisation,
  Organisation,
  OrganisationSchema,
} from "./model/Organisation.ts";

export class InMemoryOrganisationRepository implements OrganisationRepository {
  private readonly datastore: Record<string, Organisation>;
  private readonly organisationParser;

  constructor() {
    this.datastore = {};
    this.organisationParser = OrganisationSchema;
  }

  fetchByOrganisationIds(ids: string[]): Promise<Organisation[]> {
    return Promise.resolve(
      structuredClone(ids.map((id) => this.datastore[id])),
    );
  }

  find(): Promise<Organisation[]> {
    return Promise.resolve(structuredClone(Object.values(this.datastore)));
  }

  async save(org: NewOrganisation | Organisation): Promise<Organisation> {
    const parseResult = OrganisationSchema.safeParse(org);
    const id = parseResult.success
      ? parseResult.data.id
      : Md5.hashStr(org.internalNote);

    const savedOrg = { ...org, id };
    this.datastore[id] = structuredClone(
      await this.organisationParser.parseAsync(savedOrg),
    );

    return structuredClone(savedOrg);
  }

  fetchByOrganisationId(id: string): Promise<Organisation | undefined> {
    try {
      return Promise.resolve(structuredClone(this.datastore[id]));
    } catch (e) {
      return Promise.resolve(undefined);
    }
  }
}
