import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";

export enum Theme {
  Auto = "Auto",
  Dark = "Dark",
  Light = "Light",
}

interface ITheme {
  theme: Theme;
  setTheme: Dispatch<SetStateAction<Theme>>;
}

export const ThemeContext = createContext<ITheme>({
  theme: Theme.Auto,
  setTheme: () => {
    // Implemented below
  },
});

export const ThemeProvider = ({ children }: PropsWithChildren) => {
  const [theme, setTheme] = useState<Theme>(getInitialState);

  useEffect(() => {
    localStorage.setItem("theme", JSON.stringify(theme));
  }, [theme]);

  const contextValue = useMemo(() => ({ theme, setTheme }), [theme]);

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
};

function getInitialState() {
  const theme = localStorage.getItem("theme");
  return theme ? (JSON.parse(theme) as Theme) : Theme.Auto;
}
