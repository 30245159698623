import { AdminPageLayout } from "../../../components/layout/in-page/AdminPageLayout.tsx";
import { ModuleVersion } from "../../../repositories/module/model/module.ts";
import { useLatestModuleVersions } from "../../../utilities/contexts/data-cache/useLatestModuleVersions.tsx";
import { CreateSurveyDefinitionModal } from "../modals/CreateSurveyDefinitionModal.tsx";
import { ExpandableDetails } from "../modals/ExpandableDetails.tsx";

export const SurveyPageListPage = () => {
  const versions = useLatestModuleVersions();
  return (
    <AdminPageLayout
      buttons={<CreateSurveyDefinitionModal />}
      title="Survey Pages"
    >
      <ul className="list w-full">
        {(versions
          ? Object.entries(versions)
          : ([] as [string, ModuleVersion][])
        ).map(([id, version]) => {
          return (
            <li aria-label={`Module ${version.title}`} key={id}>
              <ExpandableDetails title={version.title}>
                <ol className="list-decimal pl-4">
                  {version.questions.map((question) => (
                    <li key={question.id}>{question.title}</li>
                  ))}
                </ol>
              </ExpandableDetails>
            </li>
          );
        })}
      </ul>
    </AdminPageLayout>
  );
};
