import { ModuleRepository } from "../../repositories/module/interface.ts";
import {
  Module,
  ModuleQuestion,
  ModuleVersion,
} from "../../repositories/module/model/module.ts";
import { QuestionRepository } from "../../repositories/question/interface.ts";
import { TypesSchema } from "../../repositories/question/model/question.ts";
import { SurveyDefinitionRepository } from "../../repositories/surveyDefinition/interface.ts";
import {
  NewSurveyDefinition,
  SurveyJSPage,
  SurveyJSQuestionTypesSchema,
  SurveyQuestion,
} from "../../repositories/surveyDefinition/model/SurveyDefinition.ts";
import { getHighestVersion } from "./getHighestVersion.ts";

export class SurveyDefinitionGenerator {
  private surveyDefinitionRepo: SurveyDefinitionRepository;
  private moduleRepo: ModuleRepository;
  private questionRepo: QuestionRepository;

  constructor(
    surveyDefinitionRepo: SurveyDefinitionRepository,
    moduleRepo: ModuleRepository,
    questionRepo: QuestionRepository,
  ) {
    this.surveyDefinitionRepo = surveyDefinitionRepo;
    this.moduleRepo = moduleRepo;
    this.questionRepo = questionRepo;
  }

  /**
   * @throws {Error} If the ID for the survey has been used
   */
  async generateFullSurvey(id: string, version: string, title: string) {
    const modules = await this.moduleRepo.fetchAll();
    await this.generateSurveyForModules(id, version, title, modules);
  }

  public async generateSurveyForModules(
    id: string,
    version: string,
    title: string,
    modules: Module[],
  ) {
    if (await this.surveyDefinitionRepo.fetchById(`${id}_${version}`)) {
      throw new Error("ID already used");
    }

    const surveyDef: NewSurveyDefinition = { definition: { title, pages: [] } };

    surveyDef.definition.pages = await Promise.all(
      modules.map(async (module): Promise<SurveyJSPage> => {
        const highestVersion = getHighestVersion(Object.keys(module.versions));
        const currentVersion = module.versions[highestVersion];

        return {
          title: currentVersion.title,
          name: `${module.id}_${highestVersion}`,
          elements: await this.getElements(currentVersion),
        };
      }),
    );

    await this.surveyDefinitionRepo.save(surveyDef, id, version);
  }

  async convertQuestionToElement(
    moduleQuestion: ModuleQuestion,
  ): Promise<SurveyQuestion> {
    const question = await this.questionRepo.fetchById(moduleQuestion.id);
    const currentQuestion = question?.versions[moduleQuestion.version];

    switch (currentQuestion?.type) {
      case TypesSchema.enum.likert: {
        return {
          name: `${moduleQuestion.id}_${moduleQuestion.version}`,
          title: currentQuestion?.title ?? "",
          type: SurveyJSQuestionTypesSchema.enum.radiogroup,
          isRequired: currentQuestion?.required ?? false,
          showOtherItem: false,
          choices: currentQuestion.responses,
        };
      }
      case TypesSchema.enum.likert_with_other: {
        return {
          name: `${moduleQuestion.id}_${moduleQuestion.version}`,
          title: currentQuestion?.title ?? "",
          type: SurveyJSQuestionTypesSchema.enum.radiogroup,
          isRequired: currentQuestion?.required ?? false,
          showOtherItem: true,
          otherText: "Other (Please Specify)",
          choices: currentQuestion.responses,
        };
      }
      case TypesSchema.enum.likert_horizontal: {
        return {
          name: `${moduleQuestion.id}_${moduleQuestion.version}`,
          title: currentQuestion?.title ?? "",
          type: SurveyJSQuestionTypesSchema.enum.matrix,
          isRequired: currentQuestion?.required ?? false,
          rows: ["select answer"],
          columns: currentQuestion.responses,
        };
      }
      default: {
        return {
          name: `${moduleQuestion.id}_${moduleQuestion.version}`,
          title: currentQuestion?.title ?? "",
          type: SurveyJSQuestionTypesSchema.enum.comment,
          isRequired: currentQuestion?.required ?? false,
        };
      }
    }
  }

  private async getElements(
    currentVersion: ModuleVersion,
  ): Promise<SurveyQuestion[]> {
    return await Promise.all(
      currentVersion.questions.map((q) => {
        return this.convertQuestionToElement(q);
      }),
    );
  }
}
