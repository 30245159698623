import { z } from "zod";

export const TypesSchema = z.enum([
  "likert",
  "text",
  "likert_horizontal",
  "likert_with_other",
]);

const TextQuestionVersionSchema = z.object({
  required: z.boolean(),
  title: z.string(),
  type: z.literal(TypesSchema.Values.text),
});

export const LikertQuestionVersionSchema = z.object({
  summary: z.string().optional(),
  required: z.boolean(),
  title: z.string(),
  type: z.literal(TypesSchema.Values.likert),
  responses: z.array(z.object({ text: z.string(), value: z.number() })),
  feedback: z
    .object({
      department: z.string().optional(),
      team: z.string().optional(),
    })
    .optional(),
  impact: z.number().optional(),
  recommendation: z.string().optional(),
});

export const LikertHorizontalQuestionVersionSchema = z.object({
  summary: z.string().optional(),
  required: z.boolean(),
  title: z.string(),
  type: z.literal(TypesSchema.Values.likert_horizontal),
  responses: z.array(z.object({ text: z.string(), value: z.number() })),
  feedback: z
    .object({
      department: z.string().optional(),
      team: z.string().optional(),
    })
    .optional(),
  impact: z.number().optional(),
  recommendation: z.string().optional(),
});

export const LikertWithOtherQuestionVersionSchema = z.object({
  summary: z.string().optional(),
  required: z.boolean(),
  title: z.string(),
  type: z.literal(TypesSchema.Values.likert_with_other),
  responses: z.array(z.object({ text: z.string(), value: z.number() })),
  feedback: z
    .object({
      department: z.string().optional(),
      team: z.string().optional(),
    })
    .optional(),
  impact: z.number().optional(),
  recommendation: z.string().optional(),
});

export const QuestionWithResponsesSchema = LikertQuestionVersionSchema.or(
  LikertHorizontalQuestionVersionSchema,
).or(LikertWithOtherQuestionVersionSchema);

export const QuestionSchema = z.object({
  versions: z.record(TextQuestionVersionSchema.or(QuestionWithResponsesSchema)),
  id: z.string(),
});
export type Question = z.infer<typeof QuestionSchema>;
export const NewQuestionSchema = QuestionSchema.omit({ id: true });
export type NewQuestion = z.infer<typeof NewQuestionSchema>;
