import { z } from "zod";

export const SurveyJSQuestionTypesSchema = z.enum([
  "comment",
  "matrix",
  "radiogroup",
  "html",
]);

const ResponseSchema = z.object({
  text: z.string().optional(),
  value: z.number().optional(),
});

const SurveyJSBaseQuestionSchema = z.object({
  name: z.string(),
  title: z.string(),
  isRequired: z.boolean(),
});

export const SurveyJSTextQuestionSchema = SurveyJSBaseQuestionSchema.extend({
  type: z.literal(SurveyJSQuestionTypesSchema.enum.comment),
});

export type SurveyJSTextQuestion = z.infer<typeof SurveyJSTextQuestionSchema>;

const SurveyJSHtmlQuestionSchema = SurveyJSBaseQuestionSchema.extend({
  type: z.literal(SurveyJSQuestionTypesSchema.enum.html),
  name: z.string(),
  html: z.string(),
});

export const SurveyJSMatrixQuestionSchema = SurveyJSBaseQuestionSchema.extend({
  type: z.literal(SurveyJSQuestionTypesSchema.enum.matrix),
  rows: z.array(z.string()),
  columns: z.array(ResponseSchema),
});

export const SurveyJsRadioGroupQuestionWithOtherSchema =
  SurveyJSBaseQuestionSchema.extend({
    type: z.literal(SurveyJSQuestionTypesSchema.enum.radiogroup),
    choices: z.array(ResponseSchema),
    showOtherItem: z.boolean(),
    otherText: z.string(),
  });
export const SurveyJSRadioGroupQuestionSchema =
  SurveyJsRadioGroupQuestionWithOtherSchema.or(
    SurveyJSBaseQuestionSchema.extend({
      type: z.literal(SurveyJSQuestionTypesSchema.enum.radiogroup),
      choices: z.array(ResponseSchema),
    }),
  );

export type SurveyJSRadioGroupQuestion = z.infer<
  typeof SurveyJSRadioGroupQuestionSchema
>;

export type SurveyJSMatrixQuestion = z.infer<
  typeof SurveyJSMatrixQuestionSchema
>;

const SurveyQuestionSchema = SurveyJSTextQuestionSchema.or(
  SurveyJSMatrixQuestionSchema,
)
  .or(SurveyJSRadioGroupQuestionSchema)
  .or(SurveyJSHtmlQuestionSchema);

export type SurveyQuestion = z.infer<typeof SurveyQuestionSchema>;
const SurveyJSPageBaseSchema = z.object({
  name: z.string(),
  elements: z.array(SurveyQuestionSchema),
});

export const SurveyJSPageSchema = SurveyJSPageBaseSchema.extend({
  title: z.string().optional(),
  questionTitleLocation: z.literal("hidden").optional(),
});

export const SurveyJSDefinitionSchema = z.object({
  title: z.string(),
  pages: z.array(SurveyJSPageSchema),
  logoPosition: z.string().optional(),
});

export type SurveyJSDefinition = z.infer<typeof SurveyJSDefinitionSchema>;

export const SurveyDefinitionSchema = z.object({
  id: z.string(),
  definition: SurveyJSDefinitionSchema,
});

export const NewSurveyDefinitionSchema = SurveyDefinitionSchema.omit({
  id: true,
});

export type SurveyDefinition = z.infer<typeof SurveyDefinitionSchema>;
export type NewSurveyDefinition = z.infer<typeof NewSurveyDefinitionSchema>;
export type SurveyJSPage = z.infer<typeof SurveyJSPageSchema>;
