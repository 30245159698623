import { Module } from "../../../repositories/module/model/module.ts";
import { useRepositories } from "../repositoryProvider.tsx";
import { useDataCacheContext } from "./dataCacheContext.tsx";

export function useAllModules(): Record<string, Module> | undefined {
  const { moduleRepo } = useRepositories();
  const {
    state: { modules },
    dispatch,
  } = useDataCacheContext();

  if (!modules) {
    void (async () => {
      const modules = await moduleRepo.fetchAll();
      dispatch({
        type: "set",
        key: "modules",
        state: Object.fromEntries(modules.map((item) => [item.id, item])),
      });
    })();
  }

  return modules;
}
