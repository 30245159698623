import { z } from "zod";

export const TeamSchema = z.object({
  id: z.string(),
  name: z.string(),
  numberOfParticipants: z.number().optional(),
  numberOfSurveyResponses: z.number().optional(),
  createdDate: z.date(),
  organisationId: z.string().nullable().default(null),
  surveyId: z.string(),
  resultsAvailable: z.boolean().optional(),
  discoveryArtefactUploaded: z.boolean().optional(),
});

export const NewTeamSchema = TeamSchema.omit({
  id: true,
  organisationId: true,
});
export type Team = z.infer<typeof TeamSchema>;
export type NewTeam = z.infer<typeof NewTeamSchema>;
