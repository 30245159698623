import { SurveyResultRepository } from "./interface";
import { NewSurveyResult, SurveyResult } from "./model/surveyResult";

export class InMemorySurveyResultsRepository implements SurveyResultRepository {
  private readonly data: Record<string, SurveyResult> = {};

  fetchById(id: string): Promise<null | SurveyResult> {
    return Promise.resolve(structuredClone(this.data[id] ?? null));
  }

  save(surveyResult: NewSurveyResult, id: string): Promise<SurveyResult> {
    const sr = { ...surveyResult, id };
    this.data[id] = sr;

    return Promise.resolve(structuredClone(sr));
  }
}
