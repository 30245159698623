import { FC } from "react";
import "../../../LoadingSpinner.css";

const LoadingSpinner: FC = () => (
  <div className="mt-10 flex items-center justify-center">
    <div className="lds-ring">
      <div />
      <div />
      <div />
      <div />
    </div>
    <span>&nbsp; &nbsp; Loading...</span>
  </div>
);
export default LoadingSpinner;
