import { QueryDocumentSnapshot } from "@firebase/firestore";
import {
  collection,
  doc,
  DocumentData,
  DocumentReference,
  Firestore,
  getDocs,
  query,
  setDoc,
  Timestamp,
  where,
} from "firebase/firestore";
import { SurveyResponseRepository } from "./interface";
import {
  NewSurveyResponse,
  SurveyResponse,
  SurveyResponseSchema,
} from "./model/SurveyResponse.ts";

// Helper function to convert to Firebase Timestamp
const toDate = (timestamp: Timestamp): Date => timestamp.toDate();

// Helper function to convert from Date to Firebase Timestamp
const fromDate = (date: Date): Timestamp => Timestamp.fromDate(date);

interface FirebaseTimings extends Omit<SurveyResponse["timings"], "pages"> {
  pages: Record<string, Timestamp>;
}

interface FirebaseSurveyResponse
  extends DocumentData,
    Omit<SurveyResponse, "id" | "team" | "timings" | "participant"> {
  team: DocumentReference;
  timings: FirebaseTimings;
  participant: DocumentReference;
}

class SurveyResponseConverter {
  private readonly firestore: Firestore;

  constructor(firestore: Firestore) {
    this.firestore = firestore;
  }

  toFirestore(surveyResponse: SurveyResponse): FirebaseSurveyResponse {
    const sd = Object.fromEntries(
      Object.entries(surveyResponse).filter(([key]) => key !== "id"),
    ) as Omit<SurveyResponse, "id">;

    const pages: FirebaseTimings["pages"] = Object.fromEntries(
      Object.entries(sd.timings?.pages ?? {}).map(([key, date]) => [
        key,
        fromDate(date),
      ]),
    );

    return {
      ...sd,
      timings: { pages },
      team: doc(this.firestore, "teams", surveyResponse.team),
      participant: doc(
        this.firestore,
        "participants",
        surveyResponse.participant,
      ),
    };
  }

  fromFirestore(
    snapshot: QueryDocumentSnapshot<
      FirebaseSurveyResponse,
      FirebaseSurveyResponse
    >,
  ): SurveyResponse {
    const snapshotData = snapshot.data();
    const teamId = snapshotData.team.id;
    const participantEmail = snapshotData.participant.id;

    const pages = snapshotData.timings?.pages ?? {};
    const pageTimingsAsDateTime: Record<string, Date> = Object.fromEntries(
      Object.entries(pages).map(([key, timestamp]) => [key, toDate(timestamp)]),
    );

    return SurveyResponseSchema.parse({
      ...snapshotData,
      id: snapshot.id,
      timings: { pages: pageTimingsAsDateTime },
      team: teamId,
      participant: participantEmail,
    });
  }
}

export class FirebaseSurveyResponseRepository
  implements SurveyResponseRepository
{
  private readonly firestore: Firestore;
  private readonly converter: SurveyResponseConverter;

  constructor(firestore: Firestore) {
    this.firestore = firestore;
    this.converter = new SurveyResponseConverter(firestore);
  }

  async save(
    surveyResponse: NewSurveyResponse | SurveyResponse,
  ): Promise<SurveyResponse> {
    const parsedSurveyResponse = SurveyResponseSchema.safeParse(surveyResponse);
    const insertReference = parsedSurveyResponse.success
      ? doc(
          this.firestore,
          "survey-responses",
          parsedSurveyResponse.data.id,
        ).withConverter(this.converter)
      : doc(collection(this.firestore, "survey-responses")).withConverter(
          this.converter,
        );
    await setDoc(insertReference.withConverter(this.converter), surveyResponse);

    return { ...surveyResponse, id: insertReference.id };
  }

  async fetchByTeamIdAndEmail(
    teamId: string,
    participantEmail: string,
  ): Promise<SurveyResponse | null> {
    const surveyResponsesSnapshot = await getDocs(
      query(
        collection(this.firestore, "survey-responses"),
        where("team", "==", doc(this.firestore, "teams", teamId)),
        where(
          "participant",
          "==",
          doc(this.firestore, "participants", participantEmail),
        ),
      ).withConverter(this.converter),
    );

    return (
      surveyResponsesSnapshot.docs.map((a) => a.data()).find(() => true) ?? null
    );
  }
}
