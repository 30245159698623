import { DetailedHTMLProps, FC, InputHTMLAttributes } from "react";

type HTMLInputProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

interface CheckboxInputProps {
  defaultChecked?: HTMLInputProps["defaultChecked"];
  label: string;
  onChange: HTMLInputProps["onChange"];
  value: HTMLInputProps["value"];
  required?: HTMLInputProps["required"];
  checked?: HTMLInputProps["checked"];
}
export const CheckboxInput: FC<CheckboxInputProps> = ({
  defaultChecked,
  label,
  onChange,
  required,
  checked,
  value,
}) => {
  return (
    <div className="form-control">
      <label className="label cursor-pointer">
        <span className="label-text">{label}</span>
        <input
          required={required}
          onChange={onChange}
          type="checkbox"
          defaultChecked={defaultChecked}
          className="checkbox"
          checked={checked}
          value={value}
        />
      </label>
    </div>
  );
};
