import { FC } from "react";
import InsightsHeader from "../layout/landmarks/InsightsHeader.tsx";
import Title from "../typography/Title";

interface SignedOutProps {
  readonly handleSignIn: React.MouseEventHandler<HTMLButtonElement>;
}
export const SignedOut: FC<SignedOutProps> = ({ handleSignIn }) => {
  return (
    <div>
      <InsightsHeader />
      <Title styleClass="mt-10">Please Sign in</Title>
      <button className="btn mt-6" onClick={handleSignIn} type="button">
        Sign in
      </button>
    </div>
  );
};
