import { FC, PropsWithChildren, useEffect, useState } from "react";
import { AuthUser } from "../../utilities/contexts/auth/model/User.ts";
import { VerifyEmailComponent } from "./VerifyEmailComponent";

type SignedInProps = PropsWithChildren<{ readonly user: AuthUser }>;
export const SignedIn: FC<SignedInProps> = ({ user, children }) => {
  const [innerUser, setInnerUser] = useState(user);
  useEffect(() => {
    setInnerUser(user);
  }, [user]);

  if (innerUser?.emailVerified) {
    return children;
  } else {
    return <VerifyEmailComponent user={innerUser} />;
  }
};
