import { FC, useCallback, useContext, useEffect } from "react";
import { Theme, ThemeContext } from "../../utilities/contexts/themeProvider";
import { ThemeOption } from "./ThemeOption";

export const ThemePicker: FC = () => {
  const { theme, setTheme } = useContext(ThemeContext);
  const selectTheme = useCallback(
    (theme: Theme) => setTheme(theme),
    [setTheme],
  );

  useEffect(() => {
    document.body.setAttribute(
      "data-theme",
      theme === Theme.Auto ? "" : theme.toLowerCase(),
    );
  }, [theme]);

  return (
    <div className="dropdown">
      <div
        className="theme-picker btn-ghost btn m-1"
        role="button"
        tabIndex={0}
      >
        Theme
      </div>
      <ul className="dropdown-content menu rounded-box z-[1] w-40 bg-primary p-2 shadow">
        <li>
          <ThemeOption
            icon="✨"
            onSelected={selectTheme}
            selected={theme}
            theme={Theme.Auto}
          />
        </li>
        <li>
          <ThemeOption
            icon="🌙"
            onSelected={selectTheme}
            selected={theme}
            theme={Theme.Dark}
          />
        </li>
        <li>
          <ThemeOption
            icon="☀️"
            onSelected={selectTheme}
            selected={theme}
            theme={Theme.Light}
          />
        </li>
      </ul>
    </div>
  );
};
