import { FC } from "react";
import { ArmakuniLogomark } from "../../brands/armakuni/ArmakuniLogomark.tsx";

const InsightsHeader: FC = () => (
  <div className="mx-auto p-5 flex justify-center text-5xl normal-case items-center">
    <ArmakuniLogomark className="h-30 w-28" />
    <p>&nbsp;&nbsp;Insights</p>
  </div>
);

export default InsightsHeader;
