import { FC, PropsWithChildren, ReactNode, useId } from "react";

const emptyArray: React.JSX.Element[] = [];

type AdminPageLayoutProps = PropsWithChildren<{
  readonly title: string;
  readonly buttons?: ReactNode;
  readonly breadcrumbs?: ReactNode;
}>;
export const AdminPageLayout: FC<AdminPageLayoutProps> = ({
  children,
  buttons = emptyArray,
  title,
  breadcrumbs = emptyArray,
}: AdminPageLayoutProps) => {
  const titleId = useId();

  return (
    <article aria-labelledby={titleId} className="w-full">
      <div className="container mx-auto card bg-base-100">
        <div className="card-body">
          {breadcrumbs}
          <div className="card-actions justify-between">
            <h2 className="card-title" id={titleId}>
              {title}
            </h2>
            <div className="flex flex-row gap-2">{buttons}</div>
          </div>
          {children}
        </div>
      </div>
    </article>
  );
};
