import {
  FC,
  MouseEventHandler,
  useCallback,
  useEffect,
  useId,
  useState,
  useTransition,
} from "react";
import { Link } from "react-router-dom";

export const CookieBanner: FC = () => {
  const [isPending, startTransition] = useTransition();
  const [visible, setVisible] = useState(false);

  const agreeHandler: MouseEventHandler = useCallback(() => {
    localStorage.setItem("cookie-banner-agreed", "true");
    setVisible(false);
  }, [setVisible]);

  useEffect(() => {
    const storedValue = localStorage.getItem("cookie-banner-agreed");
    startTransition(() => {
      setVisible(() => storedValue !== "true");
    });
  }, [visible]);

  const descriptionId = useId();

  if (!visible || isPending) {
    return null;
  }

  return (
    <div
      aria-describedby={descriptionId}
      aria-label="Cookie Banner"
      className="alert alert-info rounded-none py-10 fixed bottom-0"
      role="alertdialog"
    >
      <svg
        aria-hidden="true"
        className="stroke-info shrink-0 w-6 h-6"
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </svg>
      <div id={descriptionId}>
        <span>This website uses cookies to enhance the user experience.</span>{" "}
        <span>
          By signing in or signing up, you agree to our{" "}
          <Link className="link" to="/cookie-policy">
            Cookie Policy
          </Link>{" "}
          and our{" "}
          <Link className="link" to="/privacy-policy">
            Privacy Policy
          </Link>
        </span>
      </div>
      <div>
        <button
          className="btn btn-sm btn-primary"
          onClick={agreeHandler}
          type="button"
        >
          I agree
        </button>
      </div>
    </div>
  );
};
