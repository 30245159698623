import { NewOrganisation, Organisation } from "./model/Organisation.ts";

export interface OrganisationRepository {
  /**
   * @throws {OrganisationSaveFailedError} if the operation fails
   */
  save(org: NewOrganisation | Organisation): Promise<Organisation>;

  fetchByOrganisationId(id: string): Promise<Organisation | undefined>;

  fetchByOrganisationIds(id: string[]): Promise<Organisation[]>;

  find(): Promise<Organisation[]>;
}

export class OrganisationSaveFailedError implements Error {
  message: string;
  name: string;
  additional: { org: NewOrganisation };

  constructor(org: NewOrganisation) {
    this.name = "OrganisationSaveFailedError";
    this.message = "Failed to save organisation";
    this.additional = { org: org };
  }
}
