import { ParticipantRepository } from "./interface";
import { Participant } from "./model/Participant.ts";

export class InMemoryParticipantRepository implements ParticipantRepository {
  private participant: Participant[];

  constructor() {
    this.participant = [];
  }

  save(participant: Participant) {
    this.participant = [
      ...this.participant.filter((p) => p.email !== participant.email),
      structuredClone(participant),
    ];

    return Promise.resolve();
  }

  fetchByTeamId(teamId: string): Promise<Participant[]> {
    return Promise.resolve(
      structuredClone(
        this.participant.filter(
          (participant) =>
            Array.isArray(participant.associatedTeamIds) &&
            participant.associatedTeamIds.indexOf(teamId) > -1,
        ),
      ),
    );
  }

  fetchByEmail(email: string): Promise<Participant | null> {
    return Promise.resolve(
      structuredClone(this.participant.find((p) => p.email === email) ?? null),
    );
  }
}
