import { v4 } from "uuid";
import { QuestionRepository } from "./interface";
import { NewQuestion, Question } from "./model/question";

export class InMemoryQuestionRepository implements QuestionRepository {
  private readonly data: Record<string, Question> = {};

  save(question: NewQuestion): Promise<Question> {
    const id = v4();

    this.data[id] = { ...question, id };

    return Promise.resolve(structuredClone(this.data[id]));
  }
  fetchById(id: string): Promise<null | Question> {
    return Promise.resolve(structuredClone(this.data[id] ?? null));
  }
}
